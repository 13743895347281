(function(){
  'use strict';

  angular
  .module('app')
  .component('createIntegratorComponent', {
    templateUrl: 'app/components/integrator/createIntegrator.html',
    controller: ModalCreateIntegrator,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalCreateIntegrator.$inject = ['toastr','integratorService'];

  function ModalCreateIntegrator(toastr, $integratorService) {
    var vm = this;
    vm.save = save;
    vm.cancel = cancel;
    vm.send = false;
    vm.integrator = {};



    function save() {
      vm.send = true;
      $integratorService.saveIntegrator(vm.integrator)
      .then(function(res){
        vm.send = false;
        vm.close({$value: res.data});
      }, function(err){
        toastr.error(err.data.message);
      })

    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
