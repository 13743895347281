(function () {
  angular
  .module('app')
  .factory('promotionsService', promotionsService);
})();

promotionsService.$inject = ['$http', 'url'];

function promotionsService($http, $url) {

  function index(){
    return $http.get($url.getApiUrl('/promotions'))
    .then(function(res){
      return res.data;
    })
  }
  function create(data){
    return $http.post($url.getApiUrl('/promotions'), data)
    .then(function(res){
      return res.data;
    })
  }

  function update(id, data){
    return $http.put($url.getApiUrl('/promotions/'+id), data)
    .then(function(res){
      return res.data;
    })
  }

  function destroy(id){
    return $http.delete($url.getApiUrl('/promotions/'+id))
    .then(function(res){
      return res.data;
    })
  }

  return {
    index: index,
    create: create,
    update: update,
    destroy: destroy
  };
}
