(function () {
  angular
  .module('app')
  .factory('manualAwardService', manualAwardService);
})();

manualAwardService.$inject = ['$http', 'url'];

function manualAwardService($http, $url) {

  function hashToParams(params){
    var params_url = '';

    Object.keys(params).forEach(function(item, index) {
      if (index == 0 && params[item] != null) {
        params_url += '?' + item + '=' + params[item];
      } else if (index != 0 && params[item] != null) {
        params_url += '&' + item + '=' + params[item];
      }
    });

    return params_url;
  }

  function getResultDrawsDate(data) {
    return $http.get($url.getApiUrl('/premiacion/obtener_resultados'+hashToParams(data)))
    .then(function(res){
      return res.data;
    })
  }

  function doPremio(data, url) {
    return $http.post($url.getApiUrl(url), data)
    .then(function(res){
      return res.data;
    })
  }

  function doPremioPolla(data){
    return $http.post($url.getApiUrl('/premiacion_polla'), data)
    .then(function(res){
      return res.data;
    })
  }
  function doRePremioPolla(data){
    return $http.post($url.getApiUrl('/premiacion_polla/re_award'), data)
    .then(function(res){
      return res.data;
    })
  }

  function cancelJornada(data){
    return $http.post($url.getApiUrl('/premiacion_polla/cancel_day')  , data)
    .then(function(res){
      return res.data;
    })
  }

  function getAward(id){
    return $http.get($url.getApiUrl('/premiacion_polla/race_award?race_id='+id))
    .then(function(res){
      return res.data
    })
  }

  function payAwards(id){
    return $http.post($url.getApiUrl('/premiacion_polla/pay_awards?hippodrome_id='+id))
    .then(function(res){
      return res.data
    })
  }

  


  return {
    getResultDrawsDate: getResultDrawsDate,
    doPremio: doPremio,
    doPremioPolla: doPremioPolla,
    cancelJornada: cancelJornada,
    getAward: getAward,
    doRePremioPolla: doRePremioPolla,
    payAwards: payAwards

  };
}
