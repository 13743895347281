angular
  .module('app')
  .filter('prettyDate', prettyDate)
  .filter('prettyDate2', prettyDate2);

function prettyDate(url) {

  return function (date, format) {

    return moment(date, format).format('DD/MM/YYYY');
  };
}

function prettyDate2(){
  return function(date, formatIn, formatOut){
    return moment(date, formatIn).format(formatOut);
  }
}
