angular
  .module('app')
  .component('allPollas', {
    templateUrl: 'app/components/allPollas/allPollas.html',
    controller: AllPollasController
  });

  AllPollasController.$inject = ['hipodromoService','user','url', '$stateParams','$rootScope'];

function AllPollasController($hipodromoService, $user, $url, $stateParams, $rootScope) {
  var vm = this;

  vm.$onInit = function(){
    vm.currentUser = $user.getCurrentUser();
    vm.per = 20;
    vm.slug_data = $stateParams.id.split('-');
    vm.currentPage = 1;
    vm.data = {
      hippodrome_id: vm.slug_data[vm.slug_data.length - 1],
      per: 20,
    };
    $hipodromoService.getAllPollas(vm.data)
    .then(function(res){
      vm.allPollas = res;
      vm.pollas = _.reduce(vm.allPollas.data, function(memo, data){
        if(_.uniq(_.pluck(data.races, 'index')).length == 1){
          memo.push(data);
        }else{
          var agruped = _.groupBy(data.races, 'index');
          var polla = _.map(Object.values(agruped), function(races){
            return {
              id: data.id,
              codagen: data.codagen,
              oeration_id: data.oeration_id,
              races: races,
              stud: data.stud,
            }
          })
          memo = memo.concat(polla);
        }
        return memo;
      },[]);
      vm.data['token'] = vm.currentUser.token;
      vm.data['print'] = true;
      vm.urlPrint = $url.getApiUrl('/pollas.xlsx'+hashToParams(vm.data))
      console.log(vm.urlPrint)
    })
    
  }

  function hashToParams(params){
    var params_url = '';

    Object.keys(params).forEach(function(item, index) {
      if (index == 0 && params[item] != null) {
        params_url += '?' + item + '=' + params[item];
      } else if (index != 0 && params[item] != null) {
        params_url += '&' + item + '=' + params[item];
      }
    });

    return params_url;
  }



  $rootScope.$emit('webLayout', {data: {title: 'Hipodromos',subtitle: 'Pollas del Hipodromo', previewPage: 'Dashboard'}});

}
