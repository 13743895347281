(function () {
  angular
  .module('app')
  .factory('configDefaultProductService', configDefaultProductService);
})();

configDefaultProductService.$inject = ['$http', 'url'];

function configDefaultProductService($http, $url) {

  function getDefaultConfigs(){
    return $http.get($url.getApiUrl('/config_default_products'))
    .then(function(res){
      return res.data;
    })
  }
  function updateDefaultConfigs(data){
    return $http.put($url.getApiUrl('/config_default_products/'+data.loteria_id), data)
    .then(function(res){
      return res.data;
    })
  }


  return {
    getDefaultConfigs: getDefaultConfigs,
    updateDefaultConfigs: updateDefaultConfigs
  };
}
