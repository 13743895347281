(function(){
  'use strict';

  angular
  .module('app')
  .component('preAwardQuintico', {
    templateUrl: 'app/components/pre-award-quintico/pre-award-quintico.html',
    controller: PreAwardQuinticoController
  })

  PreAwardQuinticoController.$inject = ['$uibModal', 'toastr', '$rootScope','drawQuinticoService','miscelaneos'];


  function PreAwardQuinticoController($uibModal, toastr, $rootScope, $drawQuinticoService, $miscelaneos) {
    var vm = this;
    vm.loading = false;
    vm.random = random;
    vm.preAward = preAward;
    vm.signos = $miscelaneos.getSings();
    vm.combinations = []

    vm.$onInit = function(){
      vm.loading = false;
      $drawQuinticoService.getDraws()
      .then(function(res){
        vm.draws = res;
        vm.loading = true;
        vm.drawSelected = res[0];
      })
    }

    function viewPrePremiation(data){
      var modal = $uibModal.open({
        animation: true,
        component: 'resultPreAward',
        size: 'lg',
        resolve: {
          resultPreAwardDraw: function() {
            return data
          }
        }
      });
      modal.result.then(function(res){
      })
    }

    function random(){
      for (var i = 0; i <= 4; i++) {
        vm.combinations[i] = _.random(0,9);
      }
      vm.signSelected = vm.signos[_.random(1,12)];
    }

    function preAward(){
      vm.loading = false;
      var data = {
        sorteo_id: vm.drawQuinticoSelected.id,
        numero: vm.combinations.join(''),
        signo: parseInt(vm.signSelected.number)
      }

      if(vm.drawQuinticoSelected.status == 1){
        swal({
          title: 'Sorteo premiado',
          text: 'Desea continuar de todas formas?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          showLoaderOnConfirm: true,
          preConfirm: function() {
            return new Promise(function(resolve, reject) {
              $drawQuinticoService.preAwardQuintico(data)
              .then(function(res){
                vm.loading = true;
                resolve();
                viewPrePremiation(res);
              }, function() {
                reject('Ha ocurrido un error');
              });
            });
          },
          allowOutsideClick: false,
        });
      }else{
        $drawQuinticoService.preAwardQuintico(data)
        .then(function(res){
          vm.loading = true;
          viewPrePremiation(res);
        }, function() {
          swal('Ocurrio un error inesperado');
        });
      }

    }



    $rootScope.$emit('webLayout', {data: {title: 'Pre premiacion ',subtitle: 'Tu Quintico',previewPage: 'Dashboard', currentPage:'Quintico'}});

  }

})();
