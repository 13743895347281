(function(){
  'use strict';

  angular
  .module('app')
  .component('award', {
    templateUrl: 'app/components/award/award.html',
    controller: AwardController
  })

  AwardController.$inject = ['awardService', 'loteriasService', '$uibModal', 'toastr', '$rootScope', 'miscelaneos', '_', '$window','$state','$timeout'];


  function AwardController($awardService, $loteriasService, $uibModal, toastr, $rootScope, $miscelaneos, _, $window, $state, $timeout) {
    var vm = this;
    vm.showAward = false;
    vm.loteries = [];
    vm.showDraws = showDraws;
    vm.saveConfiguration = saveConfiguration;
    vm.saveOne  = saveOne;
    vm.refreshSlider = refreshSlider;
    vm.currentDraws = [];
    vm.showConfigs = false;
    vm.predefinir = {};
    vm.generalSettings = [
      {
        name: 'Ninguno',
        number: 0
      },{
        name: '+ Tickets',
        number: 1
      },{
        name: '- Tickets',
        number: 2
      },{
        name: '+ Agencias',
        number: 3
      },{
        name: '- Agencias',
        number: 4
      },{
        name: '+ Premios',
        number: 5
      },{
        name: '- Premios',
        number: 6
      },{
        name: '+ Agencias + Tickets',
        number: 7
      },{
        name: '+ Agencias - Tickets',
        number: 8
      },{
        name: '- Agencias + Tickets',
        number: 9
      },{
        name: '+ Agencias - Tickets',
        number: 10
      }]

    vm.options = {
      floor: 0,
      ceil: 300
    }

    function init(){
      $awardService.getSettingsAward()
      .then(function(res){
        vm.loteries = res;
      })
      var modal = $uibModal.open({
        animation: true,
        component: 'codeComponent',
        windowClass: 'createLoteriasComponent',
        backdrop: false,
        size: 'xs',
        resolve: {
          type_code: function(){
            return 1
          }
        }
      });

      modal.result.then(function(res){
        vm.showAward = true;
      }, function(err){
        toastr.error('Accion Cancelada');
      });
    }
    init();

    function showDraws(loteria){
      vm.currentDraws = _.findWhere(vm.loteries, {id: loteria}).sorteos;
      _.map(vm.currentDraws, function(data){
        data.show = false;
        if(data.config.desde_ls === 0 && data.config.hasta_ls === 0 && data.config.desde_d === 0 && data.config.hasta_d === 0){
          data.config.desde_ls = 0;
          data.config.desde_d = 0;
          data.config.hasta_ls = 100;
          data.config.hasta_d = 100;
        }
        console.log(data.config)
        return data;
      });
      vm.predefinir = _.findWhere(vm.loteries, {id: loteria}).predefinir;
      vm.showConfigs = true;
      vm.refreshSlider();
    }

    function saveConfiguration() {
      vm.send = true;
      var data = {
        tipo1: vm.predefinir.tipo1,
        tipo2: vm.predefinir.tipo2,
        tipo3: vm.predefinir.tipo3,
        tipo4: vm.predefinir.tipo4,
        tipo5: vm.predefinir.tipo5,
        tipo6: vm.predefinir.tipo6,
        tipo7: vm.predefinir.tipo7,
        tipo8: vm.predefinir.tipo8,
        tipo9: vm.predefinir.tipo9,
        tipo10: vm.predefinir.tipo10,
        loteria_id: vm.loterySelected,
      }
      $awardService.saveSettingPreDefined(data)
      .then(function(res){
        vm.send = false;
        toastr.info('Guardado con exito');
      },function(err){
        vm.send = false;
        toastr.error('Ocurrio un error');
      })
    }



    function saveOne(sorteo) {
      vm.send = true;
      if(sorteo.config.id != 0){
        $awardService.updateSetting([sorteo.config])
        .then(function(res){
          vm.send = false;
          toastr.success('de premiacion actualizada con exito','Configuracion');
        })
      }else{
        $awardService.saveSetting([sorteo.config])
        .then(function(res){
          vm.send = false;
          toastr.success('de premiacion creada con exito','Configuracion');
          sorteo.config.id = res.data.id;
        })
      }
    }



    function refreshSlider() {
      $timeout(function() {
        $rootScope.$broadcast('rzSliderForceRender')
      })
    }

    $rootScope.$emit('webLayout', {data: {title: 'Premiacion',subtitle: 'configuracion de premiacion',previewPage: 'Dashboard', currentPage:'Premiacion'}});

    $rootScope.$on('awards', function(){
      init();
    })



  }

})();
