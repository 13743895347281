(function () {
  angular
  .module('app')
  .factory('userService', userService);
})();

userService.$inject = ['$http', 'url'];

function userService($http, $url) {

  function getUsers(){
    return $http.get($url.getApiUrl('/usuarios'))
    .then(function(res){
      return res.data;
    })
  }

  function createUser(data){
    return $http.post($url.getApiUrl('/usuarios'), data)
    .then(function(res){
      return res.data;
    })
  }

  function updateUser(id, data){
    return $http.put($url.getApiUrl('/usuarios/'+id), data)
    .then(function(res){
      return res.data;
    })
  }

  function deleteUser(id){
    return $http.delete($url.getApiUrl('/usuarios/'+id))
    .then(function(res){
      return res.data;
    })
  }





  return {
    getUsers: getUsers,
    createUser: createUser,
    deleteUser: deleteUser,
    updateUser: updateUser
  };
}
