angular
.module('app')
.component('webLayout', {
  templateUrl: 'app/components/layouts/web-layout.html',
  controller: WebLayoutController
});

WebLayoutController.$inject = ['$rootScope']

function WebLayoutController($rootScope) {
  var vm = this;
  vm.setTitleContent = setTitleContent;

  function setTitleContent(data) {
    vm.title = data.title;
    vm.subtitle = data.subtitle;
    vm.previewPage = data.previewPage;
    vm.currentPage = data.currentPage;
  }


  $rootScope.$on('webLayout', function(event, data){
    vm.setTitleContent(data.data);
  })

}
