angular
.module('app')
.component('loginLayout', {
  templateUrl: 'app/components/layouts/login-layout.html',
  controller: LoginLayoutController
});

function LoginLayoutController() {
  
}
