(function(){
  'use strict';
  angular
    .module('app')
    .component('configProducDefault', {
      templateUrl: 'app/components/config-default-products/config-default-products.html',
      controller: ConfigDefaultProductController
    });

    ConfigDefaultProductController.$inject = ['configDefaultProductService','$rootScope','toastr'];

  function ConfigDefaultProductController($configDefaultProductService, $rootScope,toastr) {
    var vm = this;

    vm.$onInit = function(){
      $configDefaultProductService.getDefaultConfigs()
      .then(function(res){
        vm.configsDefaults = res;
      })
    }

    vm.savePercents = function(data){
      $configDefaultProductService.updateDefaultConfigs(data)
      .then(function(res){
        toastr.success(res.message);
      }, function(err){
        toastr.error(err.data.message);
      })

    }

    $rootScope.$emit('webLayout', {data: {title: 'Configuracion de centro de apuestas',subtitle: 'configuracion default de comisiones',previewPage: 'Dashboard', currentPage:'Premiacion'}});

  }

})();
