(function(){
  'use strict';

  angular
  .module('app')
  .component('categoriasDraw', {
    templateUrl: 'app/components/draw-quintico/categorias.html',
    controller: ModalCategoriasDraw,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalCategoriasDraw.$inject = ['toastr','drawQuinticoService','$window'];

  function ModalCategoriasDraw(toastr, $drawQuinticoService, $window) {
    var vm = this;

    vm.cancel = cancel;
    vm.send = false;

    vm.$onInit = function(){
      vm.currentDraw = vm.resolve.currentDraw;
      _.map(vm.currentDraw.categorias, function(c){c.disable = true; return c});
    }

    vm.save = function(){

      $drawQuinticoService.updateCategories(vm.currentDraw.id, {categorias: vm.currentDraw.categorias})
      .then(function(res){
        $window.swal('Exito', 'Categorias actualizadas con exito', 'success');
        vm.close();
      })
    }



    function cancel() {
        vm.dismiss();
    }

  }
})();
