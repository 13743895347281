(function(){
  'use strict';

  angular
  .module('app')
  .component('hipodromoModal', {
    templateUrl: 'app/components/hipodromos/hipodromoModal.html',
    controller: HipodromoModal,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  HipodromoModal.$inject = ['toastr','hipodromoService','_','$rootScope','miscelaneos','externals'];

  function HipodromoModal(toastr, $hipodromoService, _, $rootScope, $miscelaneos, $externals) {
    var vm = this;
    vm.save = save;
    vm.cancel = cancel;
    vm.send = false;
    vm.activeTap = 0;

    vm.$onInit = function(){
      $miscelaneos.availableTimeZones()
      .then(function(res){
        vm.timezones = res;
      })
      vm.hipodromo = vm.resolve.hippodrome;
      if(vm.hipodromo.hasOwnProperty('id')){
        vm.hipodromo.set_price = vm.hipodromo.price > 0;
      }
      $miscelaneos.currency.query().$promise
      .then(function(res){
        vm.moneda = res[0];
      })
    }

    function save() {
      if(vm.hipodromo.set_price == false){
        vm.hipodromo.price = 0;
        vm.hipodromo.price_reference = 0;
        vm.hipodromo.price_reference_usd = vm.moneda.usd;
      }
      if(vm.hipodromo.id){
        $hipodromoService.updateHippdromes(vm.hipodromo)
        .then(function(res){
          vm.send = false;
          toastr.success(res.message);
          vm.close({$value: res.data});
        }, function(err){
          toastr.error('Hubo un error');
        })
      }else{
        $hipodromoService.createHippdromes(vm.hipodromo)
        .then(function(res){
          vm.send = false;
          toastr.success(res.message);
          vm.close({$value: res.data});
        }, function(err){
          toastr.error('Hubo un error');
        })
      }
    }

    function cancel() {
        vm.dismiss();
    }

    vm.calculatePrice = function(currency){
      if(currency == 'usd'){
        vm.hipodromo.price_reference = (parseFloat(vm.hipodromo.price) * parseFloat(vm.moneda.usd)).toFixed(2);
      }else{
        vm.hipodromo.price = (parseFloat(vm.hipodromo.price_reference) / parseFloat(vm.moneda.usd)).toFixed(2);
      }
    }
  }
})();
