(function(){
  'use strict';

  angular
  .module('app')
  .component('loading', {
    templateUrl: 'app/components/miscelaneos/loading.html',
    controller: loadingController
  })

  loadingController.$inject = [];

  function loadingController() {}

})();
