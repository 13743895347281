(function() {
  'use strict';
  angular
    .module('app')
    .run(run)
    .run(actionCable);

  run.$inject = ['$rootScope', '$state', 'authService', '$document', '$transitions', 'user'];
  actionCable.$inject = ['ActionCableConfig','BASE_URL']

  function actionCable(ActionCableConfig, BASE_URL){
    if(BASE_URL.split('/')[2].split(':')[0] == 'localhost'){
      ActionCableConfig.wsUri = "ws://"+BASE_URL.split('/')[2]+"/cable";
    }else{
      ActionCableConfig.wsUri = "wss://"+BASE_URL.split('/')[2]+"/cable";
    }
      ActionCableConfig.autoStart = true;
  }

  function run($rootScope, $state, $authService, $document, $transitions, $user) {
    $transitions.onSuccess({ }, function() {
      $document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;
    });

    $transitions.onError({ }, function(trans) {
      switch (trans.error().detail) {
        case 'ALREADY_LOGGED_IN':
          $state.go('home');
          break;
        case 'UNAUTHORIZED':
          unauthorizedHandler();
          break;
        default:
          $state.go('404');
      }
    });

    $rootScope.$on('UNAUTHORIZED', unauthorizedHandler);

    function unauthorizedHandler() {
      if ($user.getCurrentUser()) {
        $authService.logout();
      }
      $state.go('auth');
    }


  }

})();
