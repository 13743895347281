angular
  .module('app')
  .component('hipodromos', {
    templateUrl: 'app/components/hipodromos/hipodromos.html',
    controller: HipodromosController
  });

  HipodromosController.$inject = ['hipodromoService','$rootScope','$uibModal', 'toastr'];

function HipodromosController($hipodromoService, $rootScope, $uibModal, toastr) {
  var vm = this;
  vm.create = create;
  vm.edit = edit;

  vm.$onInit = function(){
    $hipodromoService.getHippodromes()
    .then(function(res){
      vm.hippodromes = res;
      console.log(vm.hippodromes)
    })
  }

  function create(){
    var modal = $uibModal.open({
      animation: true,
      component: 'hipodromoModal',
      size: 'md'
    });

    modal.result.then(function(res){
      vm.hippodromes.push(res)
    }, function(err){
      toastr.error('Accion cancelada');
    });
  }

  function edit(data) {
    var modal = $uibModal.open({
      animation: true,
      component: 'hipodromoModal',
      size: 'xs',
      resolve: {
        hippodrome: function(){
          return data;
        }
      }
    })

    modal.result.then(function(res){
      vm.$onInit();
    });
  }

  vm.upload = function(data){
    var modal = $uibModal.open({
      animation: true,
      component: 'uploadFileHipo',
      size: 'xs',
      resolve: {
        hipodromo: function(){
          return data;
        }
      }
    })

    modal.result.then(function(res){
      vm.$onInit();
    });
  }

  $rootScope.$on('users', function(event, data){
    init();
  })



  $rootScope.$emit('webLayout', {data: {title: 'Hipodromos',subtitle: 'Administracion de hipodromos', previewPage: 'Dashboard'}});

}
