(function(){
  'use strict';
  angular
  .module('app')
  .component('numberReport', {
    templateUrl: 'app/components/number-report/number-report.html',
    controller: NumberReportController
  });

  NumberReportController.$inject = ['$rootScope', 'reportsService', 'structureService','loteriasService','user'];

  function NumberReportController($rootScope, $reportsService, $structureService, $loteriasService, $user) {
    var vm = this;
    vm.send = false;
    vm.date = new Date();
    vm.resultReports = false;
    vm.types = [{tipo:1,description:'Triple'},{tipo:2,description:'Terminal'}];
    vm.filter = vm.types[0].tipo;
    vm.currentUser = $user.getCurrentUser();


    vm.$onInit = function(){
      vm.structuras = [];
      vm.structuras.push({id: 0, nombre: 'Ver todos'});
      vm.resultReports = false;
      $structureService.getComercializadoras()
      .then(function(res){
        if(vm.currentUser.tipo == 3){
          vm.structuras = undefined;
        }else{
          vm.structuras = vm.structuras.concat(res);
          vm.estructuraSelected = vm.structuras[0];
        }
      })
      $loteriasService.getAllDraws()
      .then(function(res){
        if(!vm.currentUser.is_monitor){
          vm.sorteos = res;
        }else{
          vm.sorteos = _.sortBy(angular.fromJson(vm.currentUser.sorteos), 'id');
        }
      })
    }

    vm.getNumbers = function(){
      var data = {
        estructura_id: vm.currentUser.tipo == 3 ? _.pluck(angular.fromJson(vm.currentUser.comercializadores), 'id') : vm.estructuraSelected.id,
        tipo: vm.filter,
        sorteo_id:vm.sorteoSelected.id
      }
      $reportsService.getNumbers(data)
      .then(function(res){
        vm.reportNumbers = undefined;
        vm.reportNumbers = res;
      })
    }


    $rootScope.$emit('webLayout', {data: {title: 'Reporte',subtitle: 'Reporte de numeros mas vendidos', previewPage: 'Dashboard'}});

  }


})();
