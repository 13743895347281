(function(){
  'use strict';

  angular
  .module('app')
  .component('categoriasDefault', {
    templateUrl: 'app/components/draw-quintico/categorias.html',
    controller: ModalCategoriasDefaultDraw,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalCategoriasDefaultDraw.$inject = ['toastr','drawQuinticoService','$window'];

  function ModalCategoriasDefaultDraw(toastr, $drawQuinticoService, $window) {
    var vm = this;

    vm.cancel = cancel;
    vm.send = false;

    vm.$onInit = function(){
      vm.currentDraw = {nombre: 'Categorias Default', categorias: vm.resolve.defaultCategories};
      _.map(vm.currentDraw.categorias, function(c){c.disable = true; return c});
    }

    vm.save = function(){

      $drawQuinticoService.updateCategoriasDefault({categorias: vm.currentDraw.categorias})
      .then(function(res){
        $window.swal('Exito', 'Categorias actualizadas con exito', 'success');
        vm.close();
      })
    }



    function cancel() {
        vm.dismiss();
    }

  }
})();
