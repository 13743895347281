(function(){
  'use strict';
  angular
  .module('app')
  .component('reportLoteryPrint', {
    templateUrl: 'app/components/reports-lotery/report-lotery-print.html',
    controller: ReportLoteryPrintController
  });

  ReportLoteryPrintController.$inject = ['$rootScope','$window','$timeout','user'];

  function ReportLoteryPrintController($rootScope, $window, $timeout, $user) {
    var vm = this;
    vm.user = $user.getCurrentUser();

    $rootScope.$on('printReportLoteryPDF',function(evt, data){
      vm.dataTable = data.dataTable;
      vm.dataTotal = data.dataTotal;
      vm.dataCabecera = data.dataCabecera;
      $timeout(function(){
        $window.print();
      },1000)
    })

    vm.goBack = function(){
      $rootScope.$emit('goBack', {});
    }

    vm.print = function(){
      $window.print();
    }

  }

})();
