(function(){
  'use strict';

  angular
  .module('app')
  .component('drawQuintico', {
    templateUrl: 'app/components/draw-quintico/draw-quintico.html',
    controller: DrawQuinticoController
  })

  DrawQuinticoController.$inject = ['$uibModal', 'toastr', '$rootScope','drawQuinticoService'];


  function DrawQuinticoController($uibModal, toastr, $rootScope, $drawQuinticoService) {
    var vm = this;
    vm.loading = false;
    vm.viewCategories = viewCategories;
    vm.editDraw = editDraw;
    vm.viewDefaultCategories = viewDefaultCategories;

    vm.$onInit = function(){
      vm.loading = false;
      $drawQuinticoService.getDraws()
      .then(function(res){
        vm.draws = res;
        vm.loading = true;
        vm.drawSelected = res[0];
      })
      $drawQuinticoService.getDefaultCategories()
      .then(function(res){
        vm.defaultCategories = res;
      })

    }

    function viewCategories(draw){
      var modal = $uibModal.open({
        animation: true,
        component: 'categoriasDraw',
        size: 'lg',
        resolve: {
          currentDraw: function() {
            return draw
          }
        }
      });
      modal.result.then(function(res){
        vm.$onInit()
      })
    }

    function editDraw(draw){
      var modal = $uibModal.open({
        animation: true,
        component: 'editDrawQuintico',
        size: 'md',
        resolve: {
          currentDraw: function() {
            return angular.copy(draw)
          }
        }
      });
      modal.result.then(function(res){
        vm.$onInit()
      })
    }

    function viewDefaultCategories(){
      var modal = $uibModal.open({
        animation: true,
        component: 'categoriasDefault',
        size: 'lg',
        resolve: {
          defaultCategories: function(){
            return vm.defaultCategories
          }
        }
      });
      modal.result.then(function(res){
        vm.$onInit()
      })
    }

    $rootScope.$emit('webLayout', {data: {title: 'Administracion de Sorteos Quintico',subtitle: '',previewPage: 'Dashboard', currentPage:'Quintico'}});

    $rootScope.$on('raffles', function(){
      init();
    })



  }

})();
