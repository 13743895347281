(function() {
  'use strict';
  angular
    .module('app')
    .factory('url', url);

  url.$inject = ['BASE_URL','DOMAIN_URL'];

  function url(BASE_URL,DOMAIN_URL) {
    return {
      getApiUrl: getApiUrl,
      getBaseUrl: getBaseUrl,
      getAuthUrl: getAuthUrl,
      getFrontendUrl: getFrontendUrl
    };

    function getApiUrl(resource) {
      return BASE_URL + '/api' + resource;
    }

    function getAuthUrl(){
      return BASE_URL + '/login';
    }

    function getBaseUrl() {
      return BASE_URL;
    }
    function getFrontendUrl() {
      return DOMAIN_URL;
    }
  }
})();
