(function () {
  angular
  .module('app')
  .factory('homeService', homeService);
})();

homeService.$inject = ['$http', 'url'];

function homeService($http, $url) {

  function getLastSales(){
    return $http.get($url.getApiUrl('/dashboard/get_last_sales'))
    .then(function(res){
      return res.data
    });
  }

  function getDashboardCounts() {
    return $http.get($url.getApiUrl('/miscelaneos/dashboard_counts'))
    .then(function(res){
      return res.data;
    });
  }

  return {
    getDashboardCounts: getDashboardCounts,
    getLastSales: getLastSales
  };
}
