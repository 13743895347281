(function () {
  angular
  .module('app')
  .factory('hipodromoService', hipodromoService);
})();

hipodromoService.$inject = ['$http', 'url', '$resource'];

function hipodromoService($http, $url, $resource) {

  var pollaPrice = $resource($url.getApiUrl('/polla_price/:id'), { id: '@_id' }, { 'update': { method: 'PUT' } });

  function getHippodromes(){
    return $http.get($url.getApiUrl('/hippodromes'))
    .then(function(res){
      return res.data;
    })
  }

  function getHippodromeRaces(data){
    return $http.get($url.getApiUrl('/hippodromes/'+data.id+'/races.json?date='+data.date))
    .then(function(res){
      return res.data;
    })
  }

  function updateRaces(data){
    return $http.put($url.getApiUrl('/hippodromes/'+data.id+'/races/'+data.id+'.json'), data)
    .then(function(res){
      return res.data;
    })
  }

  function createRaces(data){
    return $http.post($url.getApiUrl('/hippodromes/'+data.id+'/races.json'), data)
    .then(function(res){
      return res.data;
    })
  }

  function createHippdromes(data){
    return $http.post($url.getApiUrl('/hippodromes'), data)
    .then(function(res){
      return res.data;
    })
  }

  function updateHippdromes(data){
    return $http.put($url.getApiUrl('/hippodromes/'+data.id), data)
    .then(function(res){
      return res.data;
    })
  }

  function showHippodrome(id){
    return $http.get($url.getApiUrl('/hippodromes/'+id))
    .then(function(res){
      return res.data;
    })
  }

  function deleteUser(id){
    return $http.delete($url.getApiUrl('/usuarios/'+id))
    .then(function(res){
      return res.data;
    })
  }

  function getAllPollas(data){
    return $http.get($url.getApiUrl('/pollas.json'+hashToParams(data)))
    .then(function(res){
      return res.data;
    })
  }

  function getAllPollasPrint(data){
    return $http.get($url.getApiUrl('/pollas.xlsx'+hashToParams(data)))
    .then(function(res){
      return res.data;
    })
  }
  
  function hashToParams(params){
    var params_url = '';

    Object.keys(params).forEach(function(item, index) {
      if (index == 0 && params[item] != null) {
        params_url += '?' + item + '=' + params[item];
      } else if (index != 0 && params[item] != null) {
        params_url += '&' + item + '=' + params[item];
      }
    });

    return params_url;
  }

  return {
    getHippodromes: getHippodromes,
    createHippdromes: createHippdromes,
    updateHippdromes: updateHippdromes,
    showHippodrome: showHippodrome,
    getHippodromeRaces: getHippodromeRaces,
    createRaces: createRaces,
    pollaPrice: pollaPrice,
    updateRaces: updateRaces,
    getAllPollas: getAllPollas,
    getAllPollasPrint: getAllPollasPrint
  };
}
