(function(){
  'use strict';

  angular
  .module('app')
  .component('promotions', {
    templateUrl: 'app/components/promotions/promotions.html',
    controller: PromotionsController
  })

  PromotionsController.$inject = ['promotionsService', '$uibModal', 'toastr', '$rootScope', 'miscelaneos', '_', '$window','loteriasService'];


  function PromotionsController($promotionsService, $uibModal, toastr, $rootScope, $miscelaneos, _, $window, $loteriasService) {
    var vm = this;
    vm.show = show;
    vm.save = save;
    vm.loterias = [];
    vm.update = update;
    vm.destroy = destroy;
    vm.showManageImages = false;
    vm.uploadImagen = uploadImagen;

    function init(){
      $promotionsService.index()
      .then(function(res){
        vm.promotions = res;
      })

    }
    init();

    function save() {
      var modal = $uibModal.open({
        animation: true,
        component: 'createPromotion',
        windowClass: 'createPromotion',
        size: 'lg'
      });

      modal.result.then(function(res){
        $rootScope.$emit('promotions',{});
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function update(promotion) {
      var modal = $uibModal.open({
        animation: true,
        component: 'updatePromotion',
        windowClass: 'createPromotion',
        size: 'lg',
        resolve: {
          promotion: function(){
            return angular.copy(promotion);
          }
        }
      });

      modal.result.then(function(res){
        $rootScope.$emit('promotions',{});
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function show(promotion) {
      var modal = $uibModal.open({
        animation: true,
        component: 'viewPromotion',
        windowClass: 'createLoteriasComponent',
        size: 'md',
        resolve: {
          promotion: function(){
            return promotion;
          }
        }
      });

      modal.result.then(function(res){
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function uploadImagen(){
      var modal = $uibModal.open({
        animation: true,
        component: 'uploadImageRaffleComponent',
        windowClass: 'createLoteriasComponent',
        size: 'md',
        resolve: {
          raffle: function(){
            return angular.copy(vm.currentRaffle)
          }
        }
      });

      modal.result.then(function(res){
        console.log(res);
        vm.currentRaffle.imagenes_rifa.push(res.data)
      }, function(err){
        toastr.error('Accion cancelada');
      });

    }

    function destroy(data, index) {
      swal({
        title: 'Confirmar borrado',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Borrar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $promotionsService.destroy(data.id)
            .then(function() {
              vm.promotions.splice(index, 1)
              resolve();
            }, function() {
              reject('Ha ocurrido un error');
            });
          });
        },
        allowOutsideClick: false,
      }).then(function() {
        swal(
          'Borrado!',
          'Ha sido borrado con éxito.',
          'success'
        );
      });
    }
    $rootScope.$emit('webLayout', {data: {title: 'Promociones Super Juegos',subtitle: '',previewPage: 'Dashboard', currentPage:'Promociones'}});

    $rootScope.$on('promotions', function(){
      init();
    })



  }

})();
