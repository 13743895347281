(function(){
  'use strict';

  angular
  .module('app')
  .component('manualAward', {
    templateUrl: 'app/components/manualAward/manualAward.html',
    controller: ManualAwardController
  })

  ManualAwardController.$inject = ['manualAwardService', '$uibModal', 'toastr', '$rootScope', '_','loteriasService','miscelaneos','$window','user'];


  function ManualAwardController($manualAwardService, $uibModal, toastr, $rootScope, _,$loteriasService,miscelaneos, $window, $user) {
    var vm = this;
    vm.showAward = true;
    vm.send = false;
    vm.showLoading = false;
    vm.loteries = [];
    vm.draws = [];
    vm.getDraws = getDraws;
    vm.premiar = premiar;
    vm.selectAward = selectAward;
    vm.premio = "";
    vm.awardImgeSelected = ' ';
    vm.viewImagen = viewImagen;
    vm.drawSelected = {imagen_premio: '',premiado: true};
    vm.animalitos = miscelaneos.getAnimalitos();
    vm.ligaditos = miscelaneos.getLigadito();
    vm.signs = _.map(miscelaneos.getSings(), function(data){data.active = false; return data});
    vm.setAwardNumber = setAwardNumber;
    vm.setLigaditoNumber = setLigaditoNumber;
    vm.setSing = setSing;
    vm.selectLotery = selectLotery;
    vm.user = $user.getCurrentUser()

    vm.$onInit = function(){
      vm.showLoading = true;
      $loteriasService.getLoterias()
      .then(function(res){
        vm.showLoading = false;
        if(vm.user.tipo != 3){
          vm.loteries = _.forEach(res, function(lote){delete lote.sorteos;});
        }else{
          vm.loteries = angular.fromJson(vm.user.loteria);
        }
      })
    }

    function getDraws(lotery){
      vm.showLoading = true;
      vm.drawSelected = {};
      vm.draws = [];
      var data = {
        loteria_id: vm.loterySelected.id,
        fecha: moment(vm.fecha_premio).format('YYYY-MM-DD')
      }
      $manualAwardService.getResultDrawsDate(data)
      .then(function(res){
        vm.showLoading = false;
        vm.draws = _.map(res, function(data){
          if(data.premio == ''){
            data.premiado = false;
          }else{
            data.premiado = true;
          }
          return data;
        });
      }, function(err){
        vm.showLoading = false;
        swal('info',err.data.message,'warning');
      })
    }

    function selectAward(sorteo){
      vm.premio = angular.copy(sorteo.premio)
      if(sorteo.comodin){
        selectedSings(sorteo.premio.slice(3,5))
      }
    }

    function premiar(){
      if(vm.drawSelected.comodin){
        if(vm.premio.length < 5){
          $window.swal('Tripletazo Incorrecto','','error');
          return;
        }
      }


      vm.send = true;

      var url = vm.loterySelected.ruta_premiacion;
      var data = {
        loteria_id: vm.loterySelected.id,
        premio: vm.premio.toString(),
        sorteo_id: vm.drawSelected.id,
        fecha_premio: moment(vm.fecha_premio).format('YYYY-MM-DD')
      }


      $window.swal({
        title: 'Confirma que desea Premiar: '+vm.drawSelected.nombre+'? '+' Numero: '+data.premio,
        type: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $manualAwardService.doPremio(data, url)
            .then(function(res){
              vm.send = false;
              vm.loterySelected = undefined;
              vm.draws = [];
              vm.premio = "";
              vm.fecha_premio = new Date();
              resolve();
            }, function(err) {
              vm.send = false;
              toastr.warning(err.data.message);
              reject('Error');
            });
          });
        },
        allowOutsideClick: false,
      }).then(function(res) {
        if(res.dismiss == 'cancel'){
          $window.swal('Premiacion','Cancelada','info');
        }else{
          $window.swal('Exito','Premiacion realizada con exito','success'); 
        }
      });
      vm.send = false;
    }
    function viewImagen(draw){
      vm.drawSelected = draw;
      selectAward(draw)
      if(draw.comodin){
        selectedSings(draw.premio.slice(3,5))
      }
    }
    vm.remove = function(){
      vm.awardImgeSelected = {};
    }
    function setAwardNumber(animalito){
      vm.premio = animalito.number;
    }
    function setLigaditoNumber(ligadito){
      if (vm.premio.length === 3){
        vm.premio = vm.premio.slice(1,3);
        vm.premio = vm.premio.concat(ligadito.number);
      }else{
        vm.premio = vm.premio.concat(ligadito.number);
      }
    }

    function selectLotery(){
      vm.drawSelected = {};
      vm.draws = [];
      vm.getDraws();
    }

    function setSing(signo){
      if(vm.premio.length === 0){
        toastr.warning('Indique el triple antes de seleccionar el signo');
        return;
      }
      if(vm.premio.length === 3){
        vm.premio = vm.premio.concat(signo.number);
        selectedSings(signo.number)
        return;
      }
      if(vm.premio.length === 5){
        vm.premio = vm.premio.slice(0,3)
        vm.premio = vm.premio.concat(signo.number);
        selectedSings(signo.number)
        return;
      }
      if(vm.premio.length === 4){
        vm.premio = vm.premio.slice(0,3)
        vm.premio = vm.premio.concat(signo.number);
        selectedSings(signo.number)
        return;
      }
      toastr.warning('Triple invalido');
    }

    function selectedSings(sign){
      _.forEach(vm.signs, function(signo){
        if(signo.number == sign){
          signo.active = true
        }else{
          signo.active = false;
        }
      })
    }


    //Input date
    vm.today = function() {
      vm.fecha_premio = new Date();
      console.log(vm.fecha_premio)
    };
    vm.today();

    vm.clear = function() {
      vm.date = null;
    };

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false,
      showCloseButton: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      maxDate: new Date(),
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false,
    };

    // Disable weekend selection
    function disabled(data) {
      var date = data.date,
        mode = data.mode;
      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.toggleMin();

    vm.open2 = function() {
      vm.popup2.opened = true;
    };
    vm.open1 = function(){
      vm.popup2.opened1 = true;
    }

    vm.setDate = function(year, month, day) {
      vm.date = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate','DD/MM/YYYY'];
    vm.format = vm.formats[0];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup2 = {
      opened: false,
      opened1: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }


    $rootScope.$emit('webLayout', {data: {title: 'Premiacion',subtitle: 'Premiacion Manual',previewPage: 'Dashboard', currentPage:'Premiacion Manual'}});

    $rootScope.$on('manualAward', function(){
      vm.$onInit();
    })



  }

})();
