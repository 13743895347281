(function () {
  angular
  .module('app')
  .factory('raffleService', raffleService);
})();

raffleService.$inject = ['$http', 'url'];

function raffleService($http, $url) {

  function getRaffles(){
    return $http.get($url.getApiUrl('/rifa'))
    .then(function(res){
      return res.data;
    })
  }
  function saveRaffle(data){
    return $http.post($url.getApiUrl('/rifa'), data)
    .then(function(res){
      return res.data;
    })
  }

  function updateRaffle(id, data){
    return $http.put($url.getApiUrl('/rifa/'+id), data)
    .then(function(res){
      return res.data;
    })
  }

  function destroyRaffle(id){
    return $http.delete($url.getApiUrl('/rifa/'+id))
    .then(function(res){
      return res.data;
    })
  }

  function toggleStatus(id){
    return $http.post($url.getApiUrl('/rifa/'+id))
    .then(function(res){
      return res.data;
    })
  }

  function toggleActive(data){
    return $http.post($url.getApiUrl('/imagenes_rifa/toggleActivo'), data)
    .then(function(res){
      return res.data;
    })
  }
  function doPrincipal(data){
    return $http.post($url.getApiUrl('/imagenes_rifa/doPrincipal'), data)
    .then(function(res){
      return res.data;
    })
  }
  function deleteImagen(id){
    return $http.delete($url.getApiUrl('/imagenes_rifa/'+id))
    .then(function(res){
      return res.data;
    })
  }

  return {
    getRaffles: getRaffles,
    saveRaffle: saveRaffle,
    updateRaffle: updateRaffle,
    destroyRaffle: destroyRaffle,
    toggleActive:toggleActive,
    doPrincipal:doPrincipal,
    deleteImagen: deleteImagen
  };
}
