angular
  .module('app')
  .component('races', {
    templateUrl: 'app/components/races/races.html',
    controller: RacesController
  });

  RacesController.$inject = ['hipodromoService','$rootScope','$stateParams','miscelaneos'];

function RacesController($hipodromoService, $rootScope, $stateParams, $miscelaneos) {
  var vm = this;
  vm.loading = true;
  vm.$onInit = function(){
    // vm.date = new Date();
    vm.slug_data = $stateParams.id.split('-');
    vm.data = {
      id: vm.slug_data[vm.slug_data.length - 1],
      date: vm.date
    };
    $hipodromoService.getHippodromeRaces(vm.data)
    .then(function(res){
      vm.hippodrome = res;
      console.log(vm.hippodrome)
      vm.races = res.races.length;
      if(res.races.length > 0){
        vm.date = new Date(res.races[0].date)
      }
      vm.loading = false;
    })
  }

  vm.getRaces = function(type){
    vm.loading = true;    

    if(type){
      console.log(moment(vm.dateHipodromo).format('YYYY-MM-DD HH:mm:ss'))
      $miscelaneos.converTimeZone({hippodrome_id: vm.data.id, date: moment(vm.dateHipodromo).format('YYYY-MM-DD HH:mm:ss')})
      .then(function(res){
        vm.responseConversion = res;
        vm.date = new Date(vm.responseConversion.conversionResult.dateTime);
        vm.data = {
          id: vm.slug_data[vm.slug_data.length - 1],
          date: vm.date
        };
        $hipodromoService.getHippodromeRaces(vm.data)
        .then(function(res){
          vm.loading = false;
          vm.hippodrome = res;
          vm.races = res.races.length;
        })
      }, function(err){
        swal('Error','El servicio de hora no esta disponible','error');
      })
    }
  }

  vm.createRaces = function(){
    if(vm.hippodrome.races.length > 0){
      vm.hippodrome.races = [];
    }
    for (var index = 0; index < vm.races;) {
      vm.hippodrome.races.push({
        hippodrome_id: vm.hippodrome.id,
        date: vm.date,
        number: index + 1,
        play: false,
        riders_attributes: [],
        normal_numeration: true
      })
      index++;
    }
    for (var index = vm.hippodrome.races.length - 6; index < vm.hippodrome.races.length;) {
      vm.hippodrome.races[index].play = true;
      index++;
    }
  }

  vm.changeNumeration = function(race){
    console.log(race.normal_numeration)
    race.normal_numeration = !race.normal_numeration
    console.log(race.normal_numeration)
    if(race.riders_attributes.length > 0) {
      _.forEach(race.riders_attributes, function(rider){
        rider.number = null;
      })
    }
  }
  function getNumeration(index,riders){
    var Numberkeys = ['1-A','1-B','1-C','1-X','2-A','2-B','2-C','2-X','1-A','3-B','3-C','3-X','4-A','4-B','4-C','4-X','5-A','5-B','5-C','5-X']
    if(vm.hippodrome.normal_numeration){
      return index + 1;
    }else{
      return Numberkeys[index];
    }
  }

  vm.addJinetes = function(race){
    race.riders_attributes = [];
    for (var index = 1; index <= race.cantRiders;) {
      race.riders_attributes.push({
        number: index,
        favorite: false,
        play: true
      })
      index++;
    }
  }

  vm.deleteRider = function(race, riders_attributes, arrIndex){
    if(race.riders_attributes[0].hasOwnProperty('id')){
      Swal('info','No se puede eliminar este jinete una vez creado, retire para evitar su seleccion','info')
    }else{
      riders_attributes.splice(arrIndex, 1);
      vm.redefineNumbers(riders_attributes)
      race.cantRiders = riders_attributes.length;
    }

  }

  vm.deleteRace = function(race, arrIndex){
    if(vm.hippodrome.races.hasOwnProperty(arrIndex)){
      Swal('info','No se puede eliminar esta carrera una vez creada','info')
    }else{
      vm.hippodrome.races.splice(arrIndex,1);
      vm.redefineNumbers(vm.hippodrome.races)
      vm.races = riders_attributes.length;
    }
  }


  vm.redefineNumbers = function(riders){
    _.forEach(riders, function(element, index){
      element.number = index + 1;
    })
  }

  vm.save = function(){
    if(vm.hippodrome.races[0].hasOwnProperty('id')){
      $hipodromoService.updateRaces(vm.hippodrome)
      Swal('Info','Jornada Actualizada exitosamente','success')
    }else{
      $hipodromoService.createRaces(vm.hippodrome)
      .then(function(res){
        Swal('Info',res.message,'success')
      })
    }
  }

  $rootScope.$on('users', function(event, data){
    init();
  })



  $rootScope.$emit('webLayout', {data: {title: 'Hipodromos',subtitle: 'Administracion de hipodromos', previewPage: 'Dashboard'}});

}
