(function(){
  'use strict';

  angular
  .module('app')
  .component('editUser', {
    templateUrl: 'app/components/users/createUser.html',
    controller: ModalCreateUser,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalCreateUser.$inject = ['toastr','userService','_','$rootScope','miscelaneos'];

  function ModalCreateUser(toastr, $userService, _, $rootScope, $miscelaneos) {
    var vm = this;
    vm.save = save;
    vm.cancel = cancel;
    vm.checkAll = checkAll;
    vm.uncheckAll = uncheckAll;
    vm.send = false;
    vm.activeTap = 0;


    vm.$onInit = function(){
      vm.user = vm.resolve.user;
      $miscelaneos.getComercializadores()
      .then(function(res){
        vm.comercializadores = res;
      })

      $miscelaneos.getLoteriaSorteos()
      .then(function(res){
        vm.loterias = res;
      })
      if(vm.user.is_monitor){
        vm.user.comercializadores = angular.fromJson(vm.user.comercializadores);
        vm.user.sorteos = angular.fromJson(vm.user.sorteos);
        vm.user.loteria = angular.fromJson(vm.user.loteria);
      }
      _.map(vm.user.permissions, function(data){
        data.name = data.section.name;
        data.pretty_name = data.section.pretty_name;
        return data
      })
    }

    // setTimeout(function(){

    //   vm.user.permissions = _.reduce(vm.resolve.sections, function(memo, data){
    //     memo.push({
    //       active: data.active,
    //       section_id: data.id,
    //       name: data.name,
    //       pretty_name: data.pretty_name
    //     })

    //     return memo;
    //   }, [])
    // },0);

    



    function save() {
      vm.send = true;
      // _.forEach(vm.user.permissions, function(permiso){
      //   delete permiso.name;
      //   delete permiso.pretty_name;
      // })
      if(vm.user.is_monitor == 'true' || vm.user.is_monitor){
        vm.user.sorteos = angular.toJson(vm.user.sorteos);
        vm.user.comercializadores = angular.toJson(vm.user.comercializadores);
      }
      $userService.updateUser(vm.user.id, vm.user)
      .then(function(res){
        vm.send = false;
        toastr.success(res.message);
        vm.close({$value: res.data});
      }, function(err){
        toastr.error('Hubo un error');
      })
    }

    function checkAll(){
      _.forEach(vm.user.permissions, function(res){
        res.active = true;
      })
    }

    function uncheckAll(){
      _.forEach(vm.user.permissions, function(res){
        res.active = false;
      })
    }

    vm.next = function(action){
      if(action == 'next'){
        if(vm.activeTap == 0){
          vm.activeTap = 1;
        }
      }else{
        vm.activeTap = 0;
      }
    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
