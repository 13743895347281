angular
.module('app')
.component('webHeader', {
  templateUrl: 'app/components/web-header/web-header.html',
  controller: WebHeaderController
});

WebHeaderController.$inject = ['authService'];

function WebHeaderController($authService) {
  var vm = this;
  vm.doLogout = doLogout;

  function doLogout() {
    console.log('asdas')
    $authService.logout();
  }

}
