(function(){
  'use strict';

  angular
  .module('app')
  .component('editRaffleComponent', {
    templateUrl: 'app/components/raffle/createRaffle.html',
    controller: ModalEditRaffle,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalEditRaffle.$inject = ['toastr','raffleService','moment', '$timeout','$window'];

  function ModalEditRaffle(toastr, $raffleService, moment, $timeout, $window) {
    var vm = this;
    vm.save = save;
    vm.cancel = cancel;
    vm.loadSorteos = loadSorteos;
    vm.send = false;
    $timeout(function(){
      vm.loterias = vm.resolve.loterias;
      vm.raffle = vm.resolve.raffle;
      vm.raffle.attributos = angular.fromJson(vm.raffle.attributos);
      vm.raffle.fecha = new Date(moment(vm.raffle.fecha, 'DD/MM/YYYY').add(1,'days').format('YYYY-MM-DD'));
      vm.loteriaID = _.reduce(vm.loterias, function(memo, data){
        var sorteo = _.findWhere(data.sorteos, {id: vm.raffle.sorteo_id});
        if(sorteo){
          memo = sorteo.loteria_id;
        }
        return memo;
      },'');
      vm.sorteos = _.findWhere(vm.loterias, {id: vm.loteriaID}).sorteos;
      console.log('beforeif')
      if(vm.raffle.hasOwnProperty('plan_premio')){
        vm.calcularMontoPremio();
        vm.calcularCostoTotal();
      }
    },0)



    function save() {
      vm.raffle.costo = vm.raffle.plan_premio.valor_ticket_venta_final;
      vm.raffle.propina = vm.raffle.plan_premio.monto_propina;
      vm.send = true;
      vm.raffle.fecha = moment(vm.raffle.fecha, 'YYYY-MM-DD').format('DD/MM/YYYY');
      vm.raffle.attributos = angular.toJson(vm.raffle.attributos);
      $raffleService.updateRaffle(vm.raffle.id, vm.raffle)
      .then(function(res){
        vm.send = false;
        toastr.info(res.message);
        vm.close({$value: res.data});
      }, function(err){
        vm.send = false;
        toastr.error(err.data.message);
      })

    }

    function cancel() {
        vm.dismiss();
    }

    function loadSorteos(loteriaID){
      vm.sorteos = _.findWhere(vm.loterias, {id: loteriaID}).sorteos;
    }

    vm.recalcularMontos = function(){
      if(vm.raffle.plan_premio.id){
        vm.calcularMontoPremio();
        vm.calcularPropina();
        vm.calcularCostoTicket();
        vm.calcularPorcentaje();
      }
    }

    vm.calcularMontoPremio = function(){
      vm.raffle.plan_premio.monto_premio = vm.raffle.plan_premio.costo * vm.raffle.plan_premio.valor_referencial;
      vm.raffle.plan_premio.monto_premio_pretty = accounting.formatMoney(vm.raffle.plan_premio.monto_premio, '','0','.',',');
    }
    vm.calcularPropina = function(){
      vm.raffle.plan_premio.monto_propina = (vm.raffle.plan_premio.porcentaje_propina * vm.raffle.plan_premio.monto_premio) / 100;
      vm.raffle.plan_premio.monto_propina = vm.raffle.plan_premio.monto_propina;
      vm.calcularCostoTotal();
    }

    vm.calcularCostoTotal = function(){
      vm.raffle.plan_premio.costo_total = parseFloat(vm.raffle.plan_premio.monto_propina) + parseFloat(vm.raffle.plan_premio.monto_premio) + parseFloat(vm.raffle.plan_premio.costo_admin);
      vm.raffle.plan_premio.costo_total_pretty = accounting.formatMoney(vm.raffle.plan_premio.costo_total, '','0','.',',');
      vm.calcularCostoTicket();
    }

    vm.calcularCostoTicket = function(){
      vm.raffle.plan_premio.valor_ticket = (vm.raffle.plan_premio.costo_total / vm.raffle.plan_premio.valor_referencial) / 12000;
      vm.raffle.plan_premio.valor_ticket_pretty = accounting.formatMoney(vm.raffle.plan_premio.valor_ticket, 'USD ','2','.',',');
      vm.calcularCostoVenta();
    }

    vm.calcularCostoVenta = function(){
      vm.raffle.plan_premio.valor_ticket_venta = (vm.raffle.plan_premio.valor_ticket * vm.raffle.plan_premio.valor_referencial);
      vm.raffle.plan_premio.valor_ticket_venta_pretty = accounting.formatMoney(vm.raffle.plan_premio.valor_ticket_venta, 'Bs ','0','.',',');
    }
    vm.calcularCostoVentaFinal = function(){
      vm.raffle.plan_premio.valor_ticket_venta_final = (vm.raffle.plan_premio.valor_ticket_venta * vm.raffle.plan_premio.porcentaje_venta) / 100;
      vm.raffle.plan_premio.valor_ticket_venta_final = redondeo(vm.raffle.plan_premio.valor_ticket_venta_final, 0);
    }

    vm.calcularPorcentaje = function(data){
      vm.raffle.plan_premio.porcentaje_venta = (vm.raffle.plan_premio.valor_ticket_venta_final / vm.raffle.plan_premio.valor_ticket_venta) * 100;
      vm.raffle.plan_premio.porcentaje_venta = redondeo(vm.raffle.plan_premio.porcentaje_venta, 0)
    }
    vm.calcularPorcentajePropina = function(){
      vm.raffle.plan_premio.porcentaje_propina = (vm.raffle.plan_premio.monto_propina / vm.raffle.plan_premio.monto_premio) * 100;
      vm.raffle.plan_premio.porcentaje_propina = redondeo(vm.raffle.plan_premio.porcentaje_propina,4)
    }


    function redondeo(numero, decimales){
      var flotante = parseFloat(numero);
      var resultado = Math. round(flotante*Math. pow(10,decimales))/Math. pow(10,decimales);
      return resultado;
    }

    vm.addAttr = function(){
      if(!vm.raffle.attributos){
        vm.raffle.attributos = [];
      }
      vm.raffle.attributos.push({
        key: '',
        value: ''
      })
    }


    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: true
    };

    vm.dateOptions = {
      dateDisabled: disabled,
      formatYear: 'yy',
      maxDate: null,
      minDate: new Date(),
      startingDay: 1
    };

    // Disable weekend selection
    function disabled(data) {
      var date = data.date,
        mode = data.mode;
      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.toggleMin();

    vm.open1 = function() {
      vm.popup1.opened = true;
    };

    vm.open2 = function() {
      vm.popup2.opened = true;
    };

    vm.setDate = function(year, month, day) {
      vm.dt = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    vm.format = vm.formats[0];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup1 = {
      opened: false
    };

    vm.popup2 = {
      opened: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }
  }
})();
