(function(){
  'use strict';
  angular
  .module('app')
  .component('reportAwardTime', {
    templateUrl: 'app/components/report-award-time/report-award-time.html',
    controller: ReportAwardTimeController
  });

  ReportAwardTimeController.$inject = ['$rootScope', 'reportsService','loteriasService'];

  function ReportAwardTimeController($rootScope, $reportsService, $loteriasService) {
    var vm = this;
    vm.send = false;



    vm.$onInit = function(){
      vm.send = true;

      $loteriasService.getLoterias()
      .then(function(res){
        vm.loterias = res;
        vm.alldraws = _.reduce(res, function(memo,data){
          memo = memo.concat(data.sorteos)
          return memo
        },[])
      });

    }

    vm.getReport = function(){
      vm.loading = true;
      var data = {
        loteria_id: vm.loteriaSelected.id,
        fecha: moment(vm.fecha).format('YYYY-MM-DD')
      }
      $reportsService.getReportAwardTime(data)
      .then(function(res){
        vm.loading = false;
        vm.premios = res;
        _.map(vm.premios, function(premio){
          var sorteo = _.findWhere(vm.alldraws, {id: premio.sorteo_id});
          if(sorteo){
            if(sorteo.tipo == 1 || sorteo.tipo == 2){
              premio.hora_j = moment(sorteo.horac_ls, 'HH:mm').add(4, 'minutes').format('HH:mm');
            }else{
              if(moment().weekday() == 0){
                premio.horac_d = sorteo.horac_d;
                premio.hora_j = moment(sorteo.horac_d, 'HH:mm').add(5, 'minutes').format('HH:mm');
              }else{
                premio.horac_ls = sorteo.horac_ls
                premio.hora_j = moment(sorteo.horac_ls, 'HH:mm').add(5, 'minutes').format('HH:mm');
              }
            }
            var hora_prem = new Date(premio.created_at).getHours() +':'+ new Date(premio.created_at).getMinutes();
            var now = moment(premio.hora_j,'HH:mm');
            var end = moment(hora_prem,'HH:mm');
            premio.minutos_diff = moment.duration(end.diff(now)).asMinutes();
            return premio;
          }
        })
      })
    }




    //Input date
    vm.today = function() {
      vm.fecha = new Date();
    };
    vm.today();

    vm.clear = function() {
      vm.date = null;
    };

    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false,
      showCloseButton: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      maxDate: new Date(),
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false,
    };

    // Disable weekend selection
    function disabled(data) {
      var date = data.date,
        mode = data.mode;
      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.toggleMin();

    vm.open2 = function() {
      vm.popup2.opened = true;
    };
    vm.open1 = function(){
      vm.popup2.opened1 = true;
    }

    vm.setDate = function(year, month, day) {
      vm.date = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate','DD/MM/YYYY'];
    vm.format = vm.formats[0];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup2 = {
      opened: false,
      opened1: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }


    $rootScope.$emit('webLayout', {data: {title: 'Reporte',subtitle: 'Reporte de horas de premiacion', previewPage: 'Dashboard'}});

  }


})();
