(function(){
  'use strict';

  angular
  .module('app')
  .component('editDrawQuintico', {
    templateUrl: 'app/components/draw-quintico/edit-draw.html',
    controller: ModalEditDrawQuintico,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalEditDrawQuintico.$inject = ['toastr','drawQuinticoService','$window'];

  function ModalEditDrawQuintico(toastr, $drawQuinticoService, $window) {
    var vm = this;

    vm.cancel = cancel;
    vm.send = false;

    vm.$onInit = function(){
      vm.currentDraw = vm.resolve.currentDraw;
      vm.currentDraw.fecha_desde = new Date(moment(vm.currentDraw.fecha_desde));
      vm.currentDraw.fecha_juega = new Date(moment(vm.currentDraw.fecha_juega));
      if(vm.currentDraw.impuesto_activo){
        vm.currentDraw.monto_total_impuesto = vm.currentDraw.monto_impuesto * vm.currentDraw.monto_boleto / 100;
      }


    }

    vm.save = function(){

      $drawQuinticoService.updateDraw(vm.currentDraw.id, vm.currentDraw)
      .then(function(res){
        $window.swal('Exito', 'Sorteo actualizado con exito', 'success');
        vm.close();
      })
    }



    function cancel() {
        vm.dismiss();
    }







    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: false
    };

    vm.dateOptions = {
      dateDisabled: false,
      formatYear: 'yy',
      maxDate: null,
      minDate: new Date(),
      startingDay: 1,
      showWeeks: false
    };

    // Disable weekend selection
    function disabled(data) {
      var date = data.date,
        mode = data.mode;
      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.toggleMin();

    vm.open1 = function() {
      vm.popup1.opened = true;
    };

    vm.open2 = function() {
      vm.popup2.opened = true;
    };

    vm.setDate = function(year, month, day) {
      vm.dt = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    vm.format = vm.formats[0];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup1 = {
      opened: false
    };

    vm.popup2 = {
      opened: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }

  }
})();
