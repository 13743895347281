angular
  .module('app')
  .filter('prettyAmount', prettyAmount);

function prettyAmount(url) {

  return function (amount) {


    return accounting.formatMoney(amount, '',2,'.',',');
  };
}
