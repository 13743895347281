(function(){
  'use strict';

  angular
  .module('app')
  .component('integrator', {
    templateUrl: 'app/components/integrator/integrator.html',
    controller: IntegratorController
  })

  IntegratorController.$inject = ['integratorService', '$uibModal', 'toastr', '$rootScope', 'miscelaneos', '_', '$window'];


  function IntegratorController($integratorService, $uibModal, toastr, $rootScope, $miscelaneos, _, $window) {
    var vm = this;
    vm.editIntegrator = editIntegrator;
    vm.removeIntegrator = removeIntegrator;
    vm.createIntegrator = createIntegrator;
    vm.toggleApiKey = toggleApiKey;


    function init(){
      $integratorService.getIntegrators()
      .then(function(res){
        vm.integrators = _.map(res, function(integrator){integrator.showApiKey = false; return integrator});
      })
    }

    init();

    function createIntegrator() {
      var modal = $uibModal.open({
        animation: true,
        component: 'createIntegratorComponent',
        windowClass: 'createLoteriasComponent',
        size: 'md',
      });

      modal.result.then(function(res){
        vm.integrators.push(res)
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function editIntegrator(integrator) {
      console.log(integrator)
      var modal = $uibModal.open({
        animation: true,
        component: 'editIntegratorComponent',
        windowClass: 'createLoteriasComponent',
        size: 'md',
        resolve: {
          integrator: function(){
            return angular.copy(integrator)
          }
        }
      });

      modal.result.then(function(res){
        $rootScope.$emit('integrators', {});
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function toggleApiKey(integrator){
      integrator.showApiKey = !integrator.showApiKey;
    }

    function removeIntegrator() {

    }

    $rootScope.$emit('webLayout', {data: {title: 'Integradores',subtitle: 'Administracion de integradores',previewPage: 'Dashboard', currentPage:'Integradores'}});

    $rootScope.$on('integrators', function(){
      init();
    })



  }

})();
