(function(){
  'use strict';

  angular
  .module('app')
  .component('auth', {
    templateUrl: 'app/components/auth/auth.html',
    controller: AuthController
  });
  AuthController.$inject = ['authService','$window', '$state','user','toastr'];


  function AuthController($authService, $window, $state, $user,toastr) {
    var vm = this;
    vm.doLogin = doLogin;
    vm.login = angular.fromJson($window.localStorage.getItem('login'));


    function doLogin() {
      if(vm.login.remember){
          $window.localStorage.setItem('login', angular.toJson(vm.login));
      }else{
        $window.localStorage.removeItem('login');
      }

      $authService.doLogin(vm.login)
      .then(function(res){
        toastr.success('Inicio de session exitoso')
        $user.setCurrentUser(res.user);
        $window.location = '/';
      }, function(err){
        toastr.error(err.data.message);
        vm.login = {};
      })
    }


  }


})();
