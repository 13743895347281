(function () {
  angular
  .module('app')
  .factory('awardService', awardService);
})();

awardService.$inject = ['$http', 'url'];

function awardService($http, $url) {

  function verifyCode(code){
    if(code == 123456){
      return true;
    }else{
      return false;
    }
    // return $http.get($url.getApiUrl('/integradores'))
    // .then(function(res){
    //   return res.data;
    // })
  }

  function getSettingsAward() {
    return $http.get($url.getApiUrl('/configuracion_premios'))
    .then(function(res){
      return res.data;
    })
  }

  function saveSetting(data){
    return $http.post($url.getApiUrl('/configuracion_premios'), data)
    .then(function(res){
      return res.data;
    })

  }

  function updateSetting(data){
    return $http.put($url.getApiUrl('/configuracion_premios/'+1), data)
    .then(function(res){
      return res.data;
    })

  }

  function saveSettingPreDefined(data){
    return $http.post($url.getApiUrl('/configuracion_premios/predefinidos'), data)
    .then(function(res){
      return res.data;
    })

  }

  return {
    verifyCode: verifyCode,
    saveSetting: saveSetting,
    getSettingsAward: getSettingsAward,
    updateSetting: updateSetting,
    saveSettingPreDefined: saveSettingPreDefined
  };
}
