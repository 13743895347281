angular
  .module('app')
  .component('home', {
    templateUrl: 'app/components/home/home.html',
    controller: HomeController
  });

  HomeController.$inject = ['homeService','$rootScope','ActionCableChannel','$timeout','$interval', 'user'];

function HomeController($homeService, $rootScope, ActionCableChannel, $timeout, $interval, $user) {
  var vm = this;
  vm.dashboard_counts = {};
  vm.data = [];
  vm.daysMultiLingual = [{en: 'Monday',es: 'Lunes'},{en: 'Tuesday',es: 'Martes'},{en: 'Wednesday',es: 'Miercoles'},{en: 'Thursday',es: 'Jueves'},{en: 'Friday',es: 'Viernes'},{en: 'Saturday',es: 'Sabado'},{en: 'Sunday',es: 'Domingo'}];
  vm.showSales = showSales;
  vm.series = ['Zoologico de la suerte', 'Chance', 'Zulia', 'Tachira', 'Zamorano'];
  vm.user = $user.getCurrentUser();


  vm.$onInit = function(){
    $homeService.getDashboardCounts()
    .then(function(res){
      vm.dashboard_counts = res;
    })
    $homeService.getLastSales()
    .then(function(res){
      var amounts = [];
      var amounts_chance = [];
      var amounts_zulia = [];
      var amounts_tachira = [];
      var amounts_zamorano = [];

      vm.labels = [];
      _.forEach(res.days, function(label){
        var translate = _.findWhere(vm.daysMultiLingual, {en: label}).es;
        vm.labels.push(translate);
      })
      _.map(res.ventas, function(venta){venta.fecha = venta.fecha.trim();return venta;});
      _.map(res.venta_chance, function(venta_l){venta_l.fecha = venta_l.fecha.trim();return venta_l;});
      _.map(res.ventas_zulia, function(venta_zulia){venta_zulia.fecha = venta_zulia.fecha.trim();return venta_zulia;});
      _.map(res.ventas_tachira, function(venta_tachira){venta_tachira.fecha = venta_tachira.fecha.trim();return venta_tachira;});
      _.map(res.venta_zamorano, function(venta_zamorano){venta_zamorano.fecha = venta_zamorano.fecha.trim();return venta_zamorano;});
      _.forEach(vm.labels, function(label){
        translate = _.findWhere(vm.daysMultiLingual, {es: label}).en
        amount = _.findWhere(res.ventas, {fecha: translate});
        amount_l = _.findWhere(res.venta_chance, {fecha: translate});
        amount_zulia = _.findWhere(res.ventas_zulia, {fecha: translate});
        amount_tachira = _.findWhere(res.ventas_tachira, {fecha: translate});
        amount_zamorano = _.findWhere(res.venta_zamorano, {fecha: translate});
        if(amount){
          amounts.push(amount.venta);
        }else{
          amounts.push('');
        }
        if(amount_l){
          amounts_chance.push(amount_l.venta);
        }else{
          amounts_chance.push('');
        }
        if(amount_zulia){
          amounts_zulia.push(amount_zulia.venta);
        }else{
          amounts_zulia.push('');
        }
        if(amount_tachira){
          amounts_tachira.push(amount_tachira.venta);
        }else{
          amounts_tachira.push('');
        }
        if(amount_zamorano){
          amounts_zamorano.push(amount_zamorano.venta);
        }else{
          amounts_zamorano.push('');
        }


      })
      vm.data.push(amounts);
      vm.data.push(amounts_chance);
      vm.data.push(amounts_zulia);
      vm.data.push(amounts_tachira);
      vm.data.push(amounts_zamorano);
    })
  };


  function showSales(point,evt){
    console.log(point)
  }

  var channel = new ActionCableChannel("DashboardChannel", {});
  var callback = function(message){
    if(message.type == 'data_sales'){
      vm.data[0][6] = message.data.zoologico;
      vm.data[1][6] = message.data.chance;
      vm.data[2][6] = message.data.zulia;
      vm.data[3][6] = message.data.tachira;
      vm.data[4][6] = message.data.zamorano;
    }
  };
  channel.subscribe(callback)
  .then(function(){
    vm.sendToMyChannel = function(message){
      channel.send(moment().format('YYYY-MM-DD'), 'obtener_ultimos_cierres');
    };
  });
  $timeout(function(){
    vm.sendToMyChannel();
  },2000)








  $rootScope.$on("$destroy", function(){
    channel.unsubscribe().then(function(){
      vm.sendToMyChannel = undefined;
    });
  });


  $rootScope.$emit('webLayout', {data: {title: 'Dashboard',subtitle: 'Resumen de la operadora',previewPage: 'Dashboard'}});

}
