(function () {
  angular
  .module('app')
  .factory('authService', authService);
})();

authService.$inject = ['$http', 'url', 'user', '$window', '$state'];

function authService($http, url, $user, $window, $state) {

  function doLogin(user) {
    return $http.post(url.getAuthUrl(), user)
      .then(function(res){
        return res.data;
      })
  }


  function logout() {
    $window.localStorage.removeItem('user');
    console.log('logout')
    $window.location = '/login'
  }

  return {
    doLogin: doLogin,
    logout: logout
  };
}
