(function(){
  'use strict';

  angular
  .module('app')
  .component('raffle', {
    templateUrl: 'app/components/raffle/raffle.html',
    controller: RaffleController
  })

  RaffleController.$inject = ['raffleService', '$uibModal', 'toastr', '$rootScope', 'miscelaneos', '_', '$window','loteriasService'];


  function RaffleController($raffleService, $uibModal, toastr, $rootScope, $miscelaneos, _, $window, $loteriasService) {
    var vm = this;
    vm.editRaffle = editRaffle;
    vm.toggleView = toggleView;
    vm.doPrincipal = doPrincipal;
    vm.uploadImagen = uploadImagen;
    vm.createRaffle = createRaffle;
    vm.toggleActive = toggleActive;
    vm.deleteImagen = deleteImagen;
    vm.showManageImages = false;
    vm.loterias = [];

    function init(){
      $raffleService.getRaffles()
      .then(function(res){
        vm.raffles = _.forEach(res, function(data){
          data.propina = parseFloat(data.propina)
        });
      })
      $loteriasService.getLoterias()
      .then(function(res){
        vm.loterias = res;
      })
    }
    init();

    function createRaffle() {
      var modal = $uibModal.open({
        animation: true,
        component: 'createRaffleComponent',
        windowClass: 'createLoteriasComponent',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          loterias: function(){
            return angular.copy(vm.loterias)
          }
        }
      });

      modal.result.then(function(res){
        vm.raffles.push(res)
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }
    init();

    function editRaffle(raffle) {
      var modal = $uibModal.open({
        animation: true,
        component: 'editRaffleComponent',
        windowClass: 'createLoteriasComponent',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          loterias: function(){
            return angular.copy(vm.loterias);
          },
          raffle: function(){
            return angular.copy(raffle);
          }
        }
      });

      modal.result.then(function(res){
        $rootScope.$emit('raffles',{});
      }, function(err){
        toastr.error('Accion cancelada');
      });
    }

    function toggleView(data){
      vm.currentRaffle = data;
      vm.showManageImages = true;
    }

    function doPrincipal(data){
      $raffleService.doPrincipal(data)
      .then(function(res){backdrop
        toastr.info(res.message)
        data.principal = res.data.principal;
        _.forEach(vm.currentRaffle.imagenes_rifa, function(img){
          if(img.id == res.data.id){
            img.principal = true;
          }
          if(img.id == res.oldPrincipal.id){
            img.principal = false;
          }
        })
        console.log(vm.currentRaffle.imagenes_rifa);
      })
    }

    function toggleActive(data){
      $raffleService.toggleActive(data)
      .then(function(res){
        toastr.info(res.message)
        data.activo = res.data.activo;
      })
    }

    function deleteImagen(imagen){
      $raffleService.deleteImagen(imagen.id)
      .then(function(res){
        toastr.success(res.message);
        vm.currentRaffle.imagenes_rifa = _.reject(vm.currentRaffle.imagenes_rifa, function(data){
          return imagen.id == data.id
        })
      })
    }

    function uploadImagen(){
      var modal = $uibModal.open({
        animation: true,
        component: 'uploadImageRaffleComponent',
        windowClass: 'createLoteriasComponent',
        size: 'md',
        resolve: {
          raffle: function(){
            return angular.copy(vm.currentRaffle)
          }
        }
      });

      modal.result.then(function(res){
        console.log(res);
        vm.currentRaffle.imagenes_rifa.push(res.data)
      }, function(err){
        toastr.error('Accion cancelada');
      });

    }
    vm.back = function(){
      vm.currentRaffle = {};
      vm.showManageImages = false;
    }

    $rootScope.$emit('webLayout', {data: {title: 'Rifas Rifcar',subtitle: '',previewPage: 'Dashboard', currentPage:'Rifcar'}});

    $rootScope.$on('raffles', function(){
      init();
    })



  }

})();
