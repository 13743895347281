angular
.module('app')
.component('asideBar', {
  templateUrl: 'app/components/aside-bar/aside-bar.html',
  controller: AsideBarController
});

AsideBarController.$inject = ['user'];

function AsideBarController($user) {
  var vm = this;
  vm.user = $user.getCurrentUser();
  console.log(vm.user.permisos.usuarios.active)

}
