(function () {
  angular
  .module('app')
  .factory('codeService', codeService);
})();

codeService.$inject = ['$http', 'url','user'];

function codeService($http, $url, $user) {

  function verifyCode(code){
    return $http.get($url.getApiUrl('/confirmation_codes/'+code))
    .then(function(res){
      return res.data;
    })
  }
  function getCode(data){
    return $http.post($url.getApiUrl('/confirmation_codes?estructura_id='+$user.getCurrentUser().estructura_id), data)
    .then(function(res){
      return res.data;
    })
  }

  function validateCodeFromApp(data){
    return $http.post($url.getApiUrl('/miscelaneos/validate_security_code'), data)
    .then(function(res){
      return res.data;
    })
  }



  return {
    verifyCode: verifyCode,
    getCode: getCode,
    validateCodeFromApp: validateCodeFromApp
  };
}
