(function(){
  'use strict';

  angular
  .module('app')
  .component('updatePromotion', {
    templateUrl: 'app/components/promotions/createPromotion.html',
    controller: ModalCreatePromotions,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalCreatePromotions.$inject = ['toastr','promotionsService','moment','Upload', 'url','$rootScope'];

  function ModalCreatePromotions(toastr, $promotionService, moment, Upload, $url, $rootScope) {
    var vm = this;
    // vm.save = save;
    vm.cancel = cancel;
    vm.send = false;
    setTimeout(function(){
      vm.promotion = vm.resolve.promotion;
      vm.promotion.file = vm.promotion.file.url;
      vm.promotion.date_of = new Date(moment(vm.promotion.date_of, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD'));
      vm.promotion.date_from = new Date(moment(vm.promotion.date_from, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD'));
    },0)



    vm.save = function() {
      vm.send = true;
      if(Upload.isFile(vm.promotion.file)){
        vm.promotion.file.upload = Upload.upload({
          url: $url.getApiUrl('/promotions/'+vm.promotion.id),
          data: vm.promotion,
          method: 'PUT'
        })

        vm.promotion.file.upload.then(function(res){
          vm.send = false;
          $rootScope.$emit('promotions',{});
          toastr.info(res.data.message);
          vm.close({$value: res.data});
        }, function(err){
          vm.send = false;
          toastr.error(err.data.message);
        });
      }else{
        $promotionService.update(vm.promotion.id, vm.promotion)
        .then(function(res){
          toastr.info(res.message)
          $rootScope.$emit('promotions',{});
          vm.close({$value: res.data});
          vm.send = false;
        }, function(err){
          toastr.error(err.data.message)
          vm.send = false;
        })
      }






    }

    function cancel() {
        vm.dismiss();
    }



    vm.inlineOptions = {
      customClass: getDayClass,
      minDate: new Date(),
      showWeeks: true
    };

    vm.dateOptions = {
      dateDisabled: '',
      formatYear: 'yyyy',
      maxDate: null,
      minDate: new Date(),
      startingDay: 1
    };

    // Disable weekend selection
    function disabled(data) {
      var date = data.date,
        mode = data.mode;
      return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
    }

    vm.toggleMin = function() {
      vm.inlineOptions.minDate = vm.inlineOptions.minDate ? null : new Date();
      vm.dateOptions.minDate = vm.inlineOptions.minDate;
    };

    vm.toggleMin();

    vm.open1 = function() {
      vm.popup1.opened = true;
    };

    vm.open2 = function() {
      vm.popup2.opened = true;
    };

    vm.setDate = function(year, month, day) {
      vm.dt = new Date(year, month, day);
    };

    vm.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    vm.format = vm.formats[0];
    vm.altInputFormats = ['M!/d!/yyyy'];

    vm.popup1 = {
      opened: false
    };

    vm.popup2 = {
      opened: false
    };

    var tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    var afterTomorrow = new Date();
    afterTomorrow.setDate(tomorrow.getDate() + 1);
    vm.events = [
      {
        date: tomorrow,
        status: 'full'
      },
      {
        date: afterTomorrow,
        status: 'partially'
      }
    ];

    function getDayClass(data) {
      var date = data.date,
        mode = data.mode;
      if (mode === 'day') {
        var dayToCheck = new Date(date).setHours(0,0,0,0);

        for (var i = 0; i < vm.events.length; i++) {
          var currentDay = new Date(vm.events[i].date).setHours(0,0,0,0);

          if (dayToCheck === currentDay) {
            return vm.events[i].status;
          }
        }
      }

      return '';
    }
  }
})();
