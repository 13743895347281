(function () {
  angular
  .module('app')
  .factory('externals', externals);
})();

externals.$inject = ['$http', 'url','$resource'];

function externals($http, $url, $resource) {

  function availableTimeZones() {
    return $http.get('http://worldtimeapi.org/api/timezone', {cache: true})
    .then(function(res){
      console.log(res)
      return res.data;
    })
  }

  function timeZones() {
    return [
      "Etc/GMT",
      "Etc/GMT-0",
      "Etc/GMT-1",
      "Etc/GMT-10",
      "Etc/GMT-11",
      "Etc/GMT-12",
      "Etc/GMT-13",
      "Etc/GMT-14",
      "Etc/GMT-2",
      "Etc/GMT-3",
      "Etc/GMT-4",
      "Etc/GMT-5",
      "Etc/GMT-6",
      "Etc/GMT-7",
      "Etc/GMT-8",
      "Etc/GMT-9",
      "Etc/GMT+0",
      "Etc/GMT+1",
      "Etc/GMT+10",
      "Etc/GMT+11",
      "Etc/GMT+12",
      "Etc/GMT+2",
      "Etc/GMT+3",
      "Etc/GMT+4",
      "Etc/GMT+5",
      "Etc/GMT+6",
      "Etc/GMT+7",
      "Etc/GMT+8",
      "Etc/GMT+9",
      "Etc/GMT0",
    ]
  }

  

  return {
    availableTimeZones: availableTimeZones,
    timeZones: timeZones
  };
}
