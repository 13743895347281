(function() {
  'use strict';
  angular
    .module('app')
    .directive('pwCheck', pwCheck);

  pwCheck.$inject = ['$http', '$q'];

  function pwCheck($http, $q) {
    return {
      require: '?ngModel',
      scope: {
        pwCheck: '=',
      },
      link: link,
    };

    function link(scope, el, attrs, ctrl) {
      if (!ctrl) return;
      var pw = scope.pwCheck;
      scope.$watch('pwCheck', function(newValue) {
          pw = newValue;
          ctrl.$validate();
      });
      ctrl.$validators.pwCheck = function(modelValue, viewValue) {
          return ctrl.$isEmpty(viewValue) || pw == viewValue;
      };
    }
  }
})();
