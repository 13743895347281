(function(){
  'use strict';

  angular
  .module('app')
  .component('viewPromotion', {
    templateUrl: 'app/components/promotions/viewPromotion.html',
    controller: modalViewPromotion,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  modalViewPromotion.$inject = [];

  function modalViewPromotion() {
    var vm = this;
    // vm.save = save;
    vm.cancel = cancel;
    vm.send = false;
    setTimeout(function(){
      vm.promotion = vm.resolve.promotion;
    },0);



    function cancel() {
        vm.dismiss();
    }
  }

})();
