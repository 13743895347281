
(function(){
  'use strict';

  angular
  .module('app')
  .component('settingsLoteryDefaultComponent', {
    templateUrl: 'app/components/loterias/lotery_settings.html',
    controller: ModalSettingsLoteryDefault,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalSettingsLoteryDefault.$inject = ['toastr','loteriasService','$rootScope'];

  function ModalSettingsLoteryDefault(toastr, $loteriasService, $rootScope) {
    var vm = this;
    vm.save = save;
    vm.cancel = cancel;
    vm.send = false;
    vm.lotery = {};

    vm.$onInit = function(){
      vm.lotery.configuracion_loteria = vm.resolve.lotery;
    }



    function save() {
      vm.send = true;
      console.log(vm.lotery.configuracion_loteria)
      $loteriasService.updateSettingsLoteryDefault(vm.lotery.configuracion_loteria)
      .then(function(res){
        vm.send = false;
        $rootScope.$emit('configuracionLoteriaDefault',{});
        toastr.success('Actualizado con exito');
        vm.close({$value: ''});
      }, function(err){
        vm.send = false;
      })

    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
