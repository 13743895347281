(function(){
  'use strict';

  angular
  .module('app')
  .component('pollaPrice', {
    templateUrl: 'app/components/polla-price/polla-price.html',
    controller: PollaPriceController
  })

  PollaPriceController.$inject = ['hipodromoService', '$uibModal','toastr','$rootScope','miscelaneos','_','$window'];


  function PollaPriceController(hipodromoService, $uibModal,toastr, $rootScope, $miscelaneos, _, $window) {
    var vm = this;
    vm.loading = true;
    
    
    vm.$onInit = function () {
      $miscelaneos.currency.query().$promise
      .then(function(res){
        vm.moneda = res[0];
      });
      hipodromoService.pollaPrice.query().$promise
      .then(function(res){
        vm.loading = false;
        vm.polla_price = res[0];
        console.log(vm.polla_price)
        vm.loading = false;
      })
    }

    vm.calculatePrice = function(currency){
      if(currency == 'usd'){
        vm.polla_price.price_reference = (parseFloat(vm.polla_price.price) * parseFloat(vm.moneda.usd)).toFixed(2);
      }else{
        vm.polla_price.price = (parseFloat(vm.polla_price.price_reference) / parseFloat(vm.moneda.usd)).toFixed(2);
      }
    }



    vm.save = function(){
      vm.send = true;
      vm.polla_price.price_reference_usd = vm.moneda.usd;
      if(vm.polla_price.id){
        hipodromoService.pollaPrice.update({id: vm.polla_price.id}, vm.polla_price).$promise
        .then(function(res){
          vm.send = false;
          toastr.success('Precio actualizado correctamente');
        });
      }else{
        hipodromoService.pollaPrice.save(vm.polla_price).$promise
        .then(function(res){
          vm.send = false;
          toastr.success('Precio actualizado correctamente');
        });
      }
    }

    $rootScope.$on('orderLoteries', function(){
      onInit();
    })

    $rootScope.$emit('webLayout', {data: {title: 'Sorteos',subtitle: 'Orden de los sorteos para la taquilla',previewPage: 'Dashboard', currentPage:'Loteria'}});

  }





})();
