(function () {
  angular
  .module('app')
  .factory('integratorService', integratorService);
})();

integratorService.$inject = ['$http', 'url'];

function integratorService($http, $url) {

  function getIntegrators(){
    return $http.get($url.getApiUrl('/integradores'))
    .then(function(res){
      return res.data;
    })
  }
  function saveIntegrator(data){
    return $http.post($url.getApiUrl('/integradores'), data)
    .then(function(res){
      return res.data;
    })
  }

  function updateIntegrator(id, data){
    return $http.put($url.getApiUrl('/integradores/'+id), data)
    .then(function(res){
      return res.data;
    })
  }

  function destroyIntegrator(id){
    return $http.delete($url.getApiUrl('/integtradores/'+id))
    .then(function(res){
      return res.data;
    })
  }

  return {
    getIntegrators: getIntegrators,
    saveIntegrator: saveIntegrator,
    updateIntegrator: updateIntegrator,
    destroyIntegrator: destroyIntegrator
  };
}
