(function(){
  'use strict';

  angular
  .module('app')
  .component('resultPreAward', {
    templateUrl: 'app/components/pre-award-quintico/result-pre-award.html',
    controller: ResultPreAwardController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ResultPreAwardController.$inject = ['toastr','drawQuinticoService','$window'];

  function ResultPreAwardController(toastr, $drawQuinticoService, $window) {
    var vm = this;
    vm.cancel = cancel;
    vm.send = false;

    vm.$onInit = function(){
      vm.resultPreAwardDraw = vm.resolve.resultPreAwardDraw;
      vm.totalticketsPremiados = _.reduce(vm.resultPreAwardDraw.datos, function(memo,data){
        memo += data.cantidad_boletos;
        return memo;
      },0);
    }


    function cancel() {
        vm.dismiss();
    }

  }
})();
