angular
  .module('app')
  .component('pollaAward', {
    templateUrl: 'app/components/pollaAward/pollaAward.html',
    controller: PollaAwardController
  });

  PollaAwardController.$inject = ['hipodromoService','$rootScope', 'toastr','manualAwardService','user'];

function PollaAwardController($hipodromoService, $rootScope, toastr, $manualAwardService, $user) {
  var vm = this;
  vm.reAward = false;
  vm.currentUser = $user.getCurrentUser();
  console.log(vm.currentUser)

  vm.$onInit = function(){
    vm.tieFirstPlace = false;
    vm.tieSecondPlace = false;
    vm.tieThridPlace = false;
    vm.reAward = false;
    vm.date = new Date();
    $hipodromoService.getHippodromes()
    .then(function(res){
      vm.hippodromes = res;
    })
  }

  vm.getHippodromeRaces = function(){
    vm.data = {
      id: vm.hippodromeSelected.id,
      date: vm.date
    };
    $hipodromoService.getHippodromeRaces(vm.data)
    .then(function(res){
      console.log(res)
      vm.hippodromeRaces = _.filter(res.races, {play: true});
      vm.races = res.races.length;
    })
  }
  vm.getRaces = function(){
    console.log(vm.date)
    vm.data = {
      id: vm.slug_data[vm.slug_data.length - 1],
      date: vm.date
    };
    $hipodromoService.getHippodromeRaces(vm.data)
    .then(function(res){
      vm.hippodrome = res;
      vm.races = res.races.length;
    })
  }

  vm.selectRider = function(rider, allRiders, place){

    if(place == 'firstPlace' && !vm.tieFirstPlace){
      _.forEach(allRiders, function(rider){
        if(rider.place == place){
          rider.place = null;
        }
      });
    }

    if(place == 'secondPlace' && !vm.tieSecondPlace){
      _.forEach(allRiders, function(rider){
        if(rider.place == place){
          rider.place = null;
        }
      });
    } 

    if(place == 'thirdPlace' && !vm.tieThridPlace){
      _.forEach(allRiders, function(rider){
        if(rider.place == place){
          rider.place = null;
        }
      });
    }
    var riderSelected = _.findWhere(allRiders, {id: rider.id});
    riderSelected.place = place;
    

    if(place == 'firstPlace' && vm.tieFirstPlace){
      riderSelected.tie = 'first_place'
    }
    if(place == 'secondPlace' && vm.tieSecondPlace){
      riderSelected.tie = 'second_place'
    }
    if(place == 'thirdPlace' && vm.tieThridPlace){
      riderSelected.tie = 'third_place'
    }

  }

  vm.save = function(){
    if(valid()){
      console.log(vm.reAward, vm.currentUser.permisos.reaward_polla.active)
      if(vm.reAward && !vm.currentUser.permisos.reaward_polla.active){
        Swal('Error','No tienes permisos para re premiar','Atención')
        return;
      }
      var ridersSelected = _.filter(vm.riders, function(rider){ return rider.place === 'firstPlace' || rider.place === 'secondPlace' || rider.place === 'thirdPlace'; });

      var params = _.reduce(ridersSelected, function(memo, data){
         memo.push({
          race_id: vm.raceSelected.id,
          rider_id: data.id,
          hippodrome_id: vm.hippodromeSelected.id,
          place: getPlaceNumer(data.place),
          tie: data.tie
         })
         return memo;
      },[])
      if(vm.reAward){
        Swal({
          title: 'Confirma Re Premiar?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          showLoaderOnConfirm: true,
          preConfirm: function() {
            return new Promise(function(resolve, reject) {
              $manualAwardService.doRePremioPolla({awards: params})
              .then(function(res){
                toastr.success('Premiacion guardada correctamente');
                resolve();
                clean();
              },function(err){
                Swal('Error',err.data.message,'Atención')
                reject('Error')
              })
            });
          },
          allowOutsideClick: false,
        });
        
          
      }else{
        Swal({
          title: 'Confirma Premiar?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          showLoaderOnConfirm: true,
          preConfirm: function() {
            return new Promise(function(resolve, reject) {
              $manualAwardService.doPremioPolla({awards: params})
              .then(function(res){
                toastr.success('Premiacion guardada correctamente');
                clean()
                resolve();
              },function(err){
                Swal('Error',err.data.message,'Atención')
                reject('Error');
              })
            });
          },
          allowOutsideClick: false,
        });
        
      }
    }
  }

  vm.findRiders = function(){
    $manualAwardService.getAward(vm.raceSelected.id)
    .then(function(res){
      vm.award = res;
      if(vm.award.length > 0){
        vm.reAward = true;
      }
      vm.riders = _.where(vm.raceSelected.riders_attributes, {play: true})
      _.forEach(vm.award, function(award){
        var rider = _.findWhere(vm.riders, {id: award.rider_id});
        rider.place = getStringPlace(award.place);
        rider.tie = award.tie;
      })
      _.forEach(vm.award, function(award){
        if(award.tie == 'first_place'){
          vm.tieFirstPlace = true;
        }
        if(award.tie == 'second_place'){
          vm.tieSecondPlace = true;
        }
        if(award.tie == 'third_place'){
          vm.tieThridPlace = true;
        }
      })
    })
  }

  vm.payAward = function(){
    Swal({
      title: 'Confirma que desea pagar el premio de la jornada de:'+ vm.hippodromeSelected.name,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      showLoaderOnConfirm: true,
      preConfirm: function() {
        return new Promise(function(resolve, reject) {
          $manualAwardService.pay_awards({hippodrome_id: vm.hippodromeSelected.id})
          .then(function(res){
            vm.hippodromeSelected = undefined;
            vm.hippodromeRaces = undefined;
            vm.raceSelected = undefined;
            vm.riders = undefined;
            resolve();
          },function(err){
            reject('Error');
          })
        });
      },
      allowOutsideClick: false,
    });
    
  }

  vm.cancelJornada = function(){
    Swal({
      title: 'Confirma que desea cancelar la jornada del hippodromo '+ vm.hippodromeSelected.name,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      showLoaderOnConfirm: true,
      preConfirm: function() {
        return new Promise(function(resolve, reject) {
          $manualAwardService.cancelJornada({hippodrome_id: vm.hippodromeSelected.id})
          .then(function(res){
            swal('Exito',res.message,'success');
            vm.hippodromeSelected = undefined;
            vm.hippodromeRaces = undefined;
            vm.raceSelected = undefined;
            vm.riders = undefined;
            resolve();
          },function(err){
            swal('Error',err.data.message,'indo')
            reject('error');
          })
        });
      },
      allowOutsideClick: false,
    });
    
  }

  vm.payAwards = function(){
    Swal({
      title: 'Confirma que pagar los premios del hipodromo '+ vm.hippodromeSelected.name,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      showLoaderOnConfirm: true,
      preConfirm: function() {
        return new Promise(function(resolve, reject) {
          $manualAwardService.payAwards(vm.hippodromeSelected.id)
          .then(function(res){
            console.log(res,'res')
            swal('Exito',res.message,'success');
            clean();
            swal('Exito',res.message,'success');
          }, function(err) {
            clean();
            swal('Error',err.data.message,'info');
            // reject('Error');
          });
        });
      },
      allowOutsideClick: false,
    })
    vm.send = false;
    
  }

  function valid(){
    var valid = true;
    if(!vm.hippodromeSelected){
      toastr.error('Debe seleccionar un hipodromo');
      valid = false;
    }
    if(!vm.raceSelected){
      toastr.error('Debe seleccionar una carrera');
      valid = false;
    }
    var riders = _.filter(vm.riders, function(rider){ return rider.place === 'firstPlace' || rider.place === 'secondPlace' || rider.place === 'thirdPlace'; })
    console.log(riders)
    if(riders.length < 3){
      toastr.error('Debe seleccionar los 3 puestos');
      valid = false;
    }
    return valid;
  }

  function getPlaceNumer(placeString){
    return {
      firstPlace: 1,
      secondPlace: 2,
      thirdPlace: 3
    }[placeString];
  }

  function getStringPlace(numberPlace){
    return {
      1: 'firstPlace',
      2: 'secondPlace',
      3: 'thirdPlace'
    }[numberPlace];
  }

  function clean(){
    vm.hippodromeSelected = undefined;
    vm.hippodromeRaces = undefined;
    vm.raceSelected = undefined;
    vm.riders = undefined;
    vm.tieFirstPlace = false;
    vm.tieSecondPlace = false;
    vm.tieThridPlace = false;
  }

  vm.selectTieFirstPlace = function(){
    vm.tieFirstPlace = !vm.tieFirstPlace
    if(vm.tieFirstPlace){
      var firstPlace = _.findWhere(vm.riders, {place: 'firstPlace'});
      if(firstPlace){
        firstPlace.place = null;
      }
      var thirdPlace = _.findWhere(vm.riders, {place: 'thirdPlace'});
      if(thirdPlace){
        thirdPlace.place = null;
      }
    }
  }

  vm.selectTieSecondPlace = function(){
    vm.tieSecondPlace = !vm.tieSecondPlace
    if(vm.tieSecondPlace){
      var secondPlace = _.findWhere(vm.riders, {place: 'secondPlace'});
      if(secondPlace){
        secondPlace.place = null;
      }
      var thirdPlace = _.findWhere(vm.riders, {place: 'thirdPlace'});
      if(thirdPlace){
        thirdPlace.place = null;
      }
    }
  }

  vm.selectTieThirdPlace = function(){
    vm.tieThridPlace = !vm.tieThridPlace
    if(vm.tieThridPlace){
      var thirdPlace = _.findWhere(vm.riders, {place: 'thirdPlace'});
      if(thirdPlace){
        thirdPlace.place = null;
      }
    }
  }

  $rootScope.$on('users', function(event, data){
    init();
  })



  $rootScope.$emit('webLayout', {data: {title: 'Hipodromos',subtitle: 'Premiacion de Pollas', previewPage: 'Dashboard'}});

}
