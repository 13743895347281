
(function(){
  'use strict';

  angular
  .module('app')
  .component('settingsLoteryComponent', {
    templateUrl: 'app/components/loterias/lotery_settings.html',
    controller: ModalSettingsLotery,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  })

  ModalSettingsLotery.$inject = ['toastr','loteriasService','$rootScope'];

  function ModalSettingsLotery(toastr, $loteriasService, $rootScope) {
    var vm = this;
    vm.save = save;
    vm.cancel = cancel;
    vm.send = false;
    vm.$onInit = function(){
      vm.lotery = vm.resolve.lotery
    }



    function save() {
      vm.send = true;
      if(vm.lotery.configuracion_loteria.id){
        $loteriasService.updateSettingsLotery(vm.lotery.id, vm.lotery.configuracion_loteria, vm.lotery.configuracion_loteria.id)
        .then(function(res){
          vm.send = false;
          $rootScope.$emit('loterias',{});
          toastr.success('Actualizado con exito');
          vm.close({$value: res.data});
        }, function(err){
          vm.send = false;
          console.log(err)
        })
      }else{
        $loteriasService.createSettingsLotery(vm.lotery.id, vm.lotery.configuracion_loteria)
        .then(function(res){
          vm.send = false;
          toastr.success('Guardado con exito');
          $rootScope.$emit('loterias',{});
          vm.close({$value: res.data});
        }, function(err){
          vm.send = false;
          console.log(err)
        })
      }
    }

    function cancel() {
        vm.dismiss();
    }
  }
})();
