(function(){
  'use strict';

  angular
  .module('app')
  .component('reportResultQuintico', {
    templateUrl: 'app/components/report-result-quintico/report-result-quintico.html',
    controller: reportResultController
  })

  reportResultController.$inject = ['$uibModal', 'toastr', '$rootScope','drawQuinticoService','reportsService'];


  function reportResultController($uibModal, toastr, $rootScope, $drawQuinticoService, $reportsService) {
    var vm = this;
    vm.loading = false;

    vm.$onInit = function(){
      vm.loading = false;
      $drawQuinticoService.getDraws()
      .then(function(res){
        vm.draws = res;
        vm.loading = true;
        vm.drawSelected = res[0];
      })
    }

    vm.search = function(){

      $reportsService.getReportResultQuintico({id: vm.drawQuinticoSelected.id})
      .then(function(res){
        vm.resultPreAwardDraw = res;
        vm.totalticketsPremiados = _.reduce(res.datos, function(memo, data){
          memo += data.cantidad_boletos;
          return memo;
        },0)
        vm.ticketsVendidos = vm.resultPreAwardDraw.total_venta / vm.drawQuinticoSelected.monto_boleto;
      })

    }

    $rootScope.$emit('webLayout', {data: {title: 'Reporte de premiacion ',subtitle: 'Tu Quintico',previewPage: 'Dashboard', currentPage:'Reportes'}});

  }

})();
