(function () {
  angular
  .module('app')
  .factory('drawQuinticoService', drawQuinticoService);
})();

drawQuinticoService.$inject = ['$http', 'url'];

function drawQuinticoService($http, $url) {

  function getDraws(){
    return $http.get($url.getApiUrl('/sorteo_quintico'))
    .then(function(res){
      return res.data;
    })
  }
  function saveDraw(data){
    return $http.post($url.getApiUrl('/sorteo_quintico'), data)
    .then(function(res){
      return res.data;
    })
  }

  function updateDraw(id, data){
    return $http.put($url.getApiUrl('/sorteo_quintico/'+id), data)
    .then(function(res){
      return res.data;
    })
  }

  function getSorteosQuintico(){
    return $http.get($url.getApiUrl('/sorteo_quintico/get_sorteo_quintico'))
    .then(function(res){
      return res.data;
    })
  }

  function updateCategories(id, data){
    return $http.post($url.getApiUrl('/sorteo_quintico/'+id+'/update_categories'), data)
    .then(function(res){
      return res.data;
    })
  }

  function getDefaultCategories() {
    return $http.get($url.getApiUrl('/sorteo_quintico/categorias_default'))
    .then(function(res){
      return res.data;
    })
  }

  function updateCategoriasDefault(data){
    return $http.post($url.getApiUrl('/sorteo_quintico/update_categorias_default'), data)
    .then(function(res){
      return res.data;
    })

  }

  function preAwardQuintico(data){
    return $http.post($url.getApiUrl('/premiacion/prepremiar_quintico'), data)
    .then(function(res){
      return res.data;
    })
  }


  return {
    getDraws: getDraws,
    saveDraw: saveDraw,
    updateDraw: updateDraw,
    getSorteosQuintico: getSorteosQuintico,
    updateCategories: updateCategories,
    getDefaultCategories: getDefaultCategories,
    updateCategoriasDefault: updateCategoriasDefault,
    preAwardQuintico: preAwardQuintico
  };
}
